.header a, .footerBox a {
    color: #444
}
.header a:hover, .footerBox a:hover, .pricingContainer a:hover {
    color: #777
}

.pricingContainer a {
    color: #212529
}
.pricingContainer a:hover {
    color: #555
}

body {
    margin:0px;
    font-family:"Avenir";
    font-weight:400;
    background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1px, transparent 1px), linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    background-size: 250px 250px, 250px 250px, 100% 100%;
    background-blend-mode: normal, normal, lighten;
    /* animation: moveBackground 80s linear infinite; */
}

img {
    max-width:100%;
    max-height:100%;
}

@keyframes moveBackground {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 0 -100%;
    }
}

.lexend {
    font-family:'Lexend';
}

.padding {
    /* padding:200px 0px; */
    flex-direction: column;
}

.pad50 {
    padding:50px 0px;
}

.pad100 {
    padding:100px 0px;
}

.pad150 {
    padding:150px 0px;
}

.pad40 {
    padding:40px;
}

.wrapper {
    flex:1;
    display:flex;
    flex-direction:row;
}

.rowItem {
    flex:1;
    display:flex;
    flex-direction: column;
    position:relative;
}

.header {
    flex:1;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.headerMain {
    padding: 10px 20px;
    border-radius: 8px;
    background-color: #eeeeee;
    border: 1px solid #cfd1d4;
}

.menu {
    gap:40px; 
    display:flex;
    flex-direction:row;
    align-items: center;
}

.menuElement {
}

.headLogo {
    font-size:24px;
    font-weight:600;
    font-family:"Avenir";
}

.heading {
    font-size:72px;
    font-weight:700;
    font-family:'Lexend';
}

.heading span {
    /* color: #FFFFFF;
    padding: 0px 12px;
    background-color: #000000; */
}

.container {
    flex:1;
    display:flex;
    margin:auto;
    width:1200px;
    max-width:100%;
    flex-direction:column;
}

.tCenter {
    text-align: center;
}

.multiPack {
    font-size:20px;
    color: #3c3c3f;
    padding:20px 0px;
}

.section.headerSection .rowItem {
    align-items: center;
    justify-content: center;
}

.headerBox {
    padding:190px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
}

.headerAction {
    gap: 20px;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:30px;
}

.mainButton {
    color:#FFFFFF;
    font-weight:500;
    padding:15px 30px;
    border-radius: 8px;
    /* border: 1px solid #000000; */
    background-color:#000000;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.mainButton:hover {
    color: #DEDEDE;
}

.secondaryButton {
    font-weight:500;
    padding:15px 30px;
    /* border-radius:999px;
    border: 2px solid #dbdce3; */
    /* border-color:transparent; */
    /* background-color: #FFFFFF; */
    /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06); */

    border-radius: 8px;
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(251, 250, 249) 100%);
    box-shadow: rgba(23, 23, 23, 0.1) 0px 0px 0px 2px, rgba(0, 0, 0, 0.06) 0px 0.361312px 0.28905px -1px, rgba(0, 0, 0, 0.055) 0px 1.37312px 1.0985px -2px, rgba(0, 0, 0, 0.04) 0px 6px 4.8px -3px;
    color: #444;
}

.secondaryButton:hover {
    color: #777;
}

.capsulePack {
    gap:12px;
    display:flex;
    flex-direction: row;
    color:#FFFFFF;
    padding: 50px 0px 0px 0px;
}

.capsuleItem {
    padding:10px 15px;
    border-radius:0px;
    background-color:#000000;
}

.capsulePack .capsuleItem:nth-child(1) {
    background-color:#F79F1F;
}

.capsulePack .capsuleItem:nth-child(2) {
    background-color:#1B1464;
}

.capsulePack .capsuleItem:nth-child(3) {
    background-color:#009432;
}

.capsulePack .capsuleItem:nth-child(4) {
    background-color:#6F1E51;
}

.capsulePack .capsuleItem:nth-child(5) {
    background-color:#006266;
}

.capsulePack .capsuleItem:nth-child(6) {
    background-color:#833471;
}

.capsulePack .capsuleItem:nth-child(7) {
    background-color:#A3CB38;
}

.capsulePack .capsuleItem:nth-child(8) {
    background-color:#D980FA;
}

.featureBox {
    gap: 12px;
    padding: 40px;
    display:flex;
    flex-direction: column;
    /* border:1px solid #cfd1d4; */
    border-radius:12px;
}

.fTitle {
    font-size:24px;
    font-weight:600;
}

.gap20 {
    gap:20px;
}

.sliderUnit {
    gap:20px;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    width:calc(180px * 50);
}

.sliderUnit::before {
    top: 0;
    left: 0;
    background: linear-gradient(to right, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    content: " ";
    position: absolute;
    z-index: 9;
    width: 180px;
    height: 100%;
}

.sliderUnit::after {
    top: 0;
    right: 0;
    background: linear-gradient(to left, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
    content: " ";
    position: absolute;
    z-index: 9;
    width: 180px;
    height: 100%;
}

.sliderUnit.two {
    transform:translateX(calc(-250px * 25));
}

.slideItemUnit {
    gap:20px;
    width:250px;
    display:flex;
    flex-direction:row;
    align-items: center;
    border-radius:8px;
    white-space: nowrap;
    position: relative; 
    background-color:#eeeeee;
    /* border: 1px solid #cfd1d4; */
    padding:10px 20px 10px 40px;
    padding: 20px 20px 20px 20px;
}
.sliderUnit.one .slideItemUnit {
    animation:translateinfinite 200s linear infinite;
}
.sliderUnit.two .slideItemUnit {
    animation:translateinfiniteBackwards 200s linear infinite;
}

@keyframes translateinfinite {
	100% { transform:translateX(calc(-250px * 25));  }
}

@keyframes translateinfiniteBackwards {
	100% { transform:translateX(calc(250px * 25));  }
}

.slideItemUnits:before {
    top: 0px;
    left: 15px;
    bottom: 0px;
    margin: auto;
    width: 10px;
    height: 10px;
    content: '';
    position: absolute;
    border-radius: 999px;
    background-color: #ffffff;
    border: 1px solid #acacac;
}

.appInitials {
    min-width:50px;
    height:50px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color:#000000;
    border-radius:8px;
    color:#FFFFFF;
    font-weight:600;
    font-size:18px;
}

.sliderUnit.one .slideItemUnit:nth-child(1) .appInitials {background-color: #FFC312;} /* Bright Yellow */
.sliderUnit.one .slideItemUnit:nth-child(2) .appInitials {background-color: #ED4C67;} /* Watermelon Red */
.sliderUnit.one .slideItemUnit:nth-child(3) .appInitials {background-color: #2f3640;} /* Green Tea */
.sliderUnit.one .slideItemUnit:nth-child(4) .appInitials {background-color: #1289A7;} /* Reef Blue */
.sliderUnit.one .slideItemUnit:nth-child(5) .appInitials {background-color: #D980FA;} /* Light Purple */
.sliderUnit.one .slideItemUnit:nth-child(6) .appInitials {background-color: #EE5A24;} /* Orange Hibiscus */
.sliderUnit.one .slideItemUnit:nth-child(7) .appInitials {background-color: #00A8FF;} /* Clear Sky Blue */
.sliderUnit.one .slideItemUnit:nth-child(8) .appInitials {background-color: #B53471;} /* Fusion Pink */
.sliderUnit.one .slideItemUnit:nth-child(9) .appInitials {background-color: #6AB04C;} /* Apple Green */
.sliderUnit.one .slideItemUnit:nth-child(10) .appInitials {background-color: #5352ED;} /* Highlighter Blue */
.sliderUnit.one .slideItemUnit:nth-child(11) .appInitials {background-color: #F79F1F;} /* Sunflower */
.sliderUnit.one .slideItemUnit:nth-child(12) .appInitials {background-color: #e84118;} /* Tomato Red */
.sliderUnit.one .slideItemUnit:nth-child(13) .appInitials {background-color: #9c88ff;} /* Soft Lavender */
.sliderUnit.one .slideItemUnit:nth-child(14) .appInitials {background-color: #4cd137;} /* Vegan Green */
.sliderUnit.one .slideItemUnit:nth-child(15) .appInitials {background-color: #8c7ae6;} /* Light Grape */
.sliderUnit.one .slideItemUnit:nth-child(16) .appInitials {background-color: #00a8ff;} /* Skies Blue */
.sliderUnit.one .slideItemUnit:nth-child(17) .appInitials {background-color: #c23616;} /* Dark Coral */
.sliderUnit.one .slideItemUnit:nth-child(18) .appInitials {background-color: #40739e;} /* Ocean Blue */
.sliderUnit.one .slideItemUnit:nth-child(19) .appInitials {background-color: #fbc531;} /* Citrus Yellow */
.sliderUnit.one .slideItemUnit:nth-child(20) .appInitials {background-color: #273c75;} /* Blue Nights */
.sliderUnit.one .slideItemUnit:nth-child(21) .appInitials {background-color: #44bd32;} /* Kiwi Green */
.sliderUnit.one .slideItemUnit:nth-child(22) .appInitials {background-color: #487eb0;} /* Deep Sea Blue */
.sliderUnit.one .slideItemUnit:nth-child(23) .appInitials {background-color: #7f8fa6;} /* Soft Gray */
.sliderUnit.one .slideItemUnit:nth-child(24) .appInitials {background-color: #e1b12c;} /* Honey Yellow */
.sliderUnit.one .slideItemUnit:nth-child(25) .appInitials {background-color: #40739e;} /* Navy Blue */
.sliderUnit.one .slideItemUnit:nth-child(26) .appInitials {background-color: #009432;} /* Bright Green */
.sliderUnit.one .slideItemUnit:nth-child(27) .appInitials {background-color: #e74c3c;} /* Alizarin Red */
.sliderUnit.one .slideItemUnit:nth-child(28) .appInitials {background-color: #e67e22;} /* Carrot Orange */
.sliderUnit.one .slideItemUnit:nth-child(29) .appInitials {background-color: #2980b9;} /* Belize Hole */
.sliderUnit.one .slideItemUnit:nth-child(30) .appInitials {background-color: #2ecc71;} /* Emerald Green */
.sliderUnit.one .slideItemUnit:nth-child(31) .appInitials {background-color: #f1c40f;} /* Sun Flower */
.sliderUnit.one .slideItemUnit:nth-child(32) .appInitials {background-color: #e84393;} /* Radical Red */
.sliderUnit.one .slideItemUnit:nth-child(33) .appInitials {background-color: #3498db;} /* Peter River */
.sliderUnit.one .slideItemUnit:nth-child(34) .appInitials {background-color: #9b59b6;} /* Amethyst */
.sliderUnit.one .slideItemUnit:nth-child(35) .appInitials {background-color: #34495e;} /* Wet Asphalt */
.sliderUnit.one .slideItemUnit:nth-child(36) .appInitials {background-color: #c0392b;} /* Pomegranate */
.sliderUnit.one .slideItemUnit:nth-child(37) .appInitials {background-color: #d35400;} /* Pumpkin */
.sliderUnit.one .slideItemUnit:nth-child(38) .appInitials {background-color: #16a085;} /* Green Sea */
.sliderUnit.one .slideItemUnit:nth-child(39) .appInitials {background-color: #27ae60;} /* Nephritis */
.sliderUnit.one .slideItemUnit:nth-child(40) .appInitials {background-color: #2980b9;} /* Belize Hole */
.sliderUnit.one .slideItemUnit:nth-child(41) .appInitials {background-color: #2c3e50;} /* Midnight Blue */
.sliderUnit.one .slideItemUnit:nth-child(42) .appInitials {background-color: #8e44ad;} /* Wisteria */
.sliderUnit.one .slideItemUnit:nth-child(43) .appInitials {background-color: #f39c12;} /* Orange */
.sliderUnit.one .slideItemUnit:nth-child(44) .appInitials {background-color: #e74c3c;} /* Alizarin */
.sliderUnit.one .slideItemUnit:nth-child(45) .appInitials {background-color: #3498db;} /* River Blue */
.sliderUnit.one .slideItemUnit:nth-child(46) .appInitials {background-color: #9b59b6;} /* Purple Violet */
.sliderUnit.one .slideItemUnit:nth-child(47) .appInitials {background-color: #2ecc71;} /* Bright Emerald */
.sliderUnit.one .slideItemUnit:nth-child(48) .appInitials {background-color: #34495e;} /* Dark Slate */
.sliderUnit.one .slideItemUnit:nth-child(49) .appInitials {background-color: #c0392b;} /* Strong Red */
.sliderUnit.one .slideItemUnit:nth-child(50) .appInitials {background-color: #f39c12;} /* Bright Orange */
.sliderUnit.two .slideItemUnit:nth-child(1) .appInitials {background-color: #E91E63;} /* Pink */
.sliderUnit.two .slideItemUnit:nth-child(2) .appInitials {background-color: #2196F3;} /* Bright Blue */
.sliderUnit.two .slideItemUnit:nth-child(3) .appInitials {background-color: #FF5722;} /* Deep Orange */
.sliderUnit.two .slideItemUnit:nth-child(4) .appInitials {background-color: #009688;} /* Teal */
.sliderUnit.two .slideItemUnit:nth-child(5) .appInitials {background-color: #673AB7;} /* Deep Purple */
.sliderUnit.two .slideItemUnit:nth-child(6) .appInitials {background-color: #FFC107;} /* Amber */
.sliderUnit.two .slideItemUnit:nth-child(7) .appInitials {background-color: #B53471;} /* Brown */
.sliderUnit.two .slideItemUnit:nth-child(8) .appInitials {background-color: #3F51B5;} /* Indigo */
.sliderUnit.two .slideItemUnit:nth-child(9) .appInitials {background-color: #8BC34A;} /* Light Green */
.sliderUnit.two .slideItemUnit:nth-child(10) .appInitials {background-color: #00a8ff;} /* Lime */
.sliderUnit.two .slideItemUnit:nth-child(11) .appInitials {background-color: #9C27B0;} /* Purple */
.sliderUnit.two .slideItemUnit:nth-child(12) .appInitials {background-color: #FFEB3B;} /* Yellow */
.sliderUnit.two .slideItemUnit:nth-child(13) .appInitials {background-color: #FF9800;} /* Orange */
.sliderUnit.two .slideItemUnit:nth-child(14) .appInitials {background-color: #03A9F4;} /* Light Blue */
.sliderUnit.two .slideItemUnit:nth-child(15) .appInitials {background-color: #00BCD4;} /* Cyan */
.sliderUnit.two .slideItemUnit:nth-child(16) .appInitials {background-color: #F44336;} /* Red */
.sliderUnit.two .slideItemUnit:nth-child(17) .appInitials {background-color: #4CAF50;} /* Green */
.sliderUnit.two .slideItemUnit:nth-child(18) .appInitials {background-color: #607D8B;} /* Blue Gray */
.sliderUnit.two .slideItemUnit:nth-child(19) .appInitials {background-color: #FF6F61;} /* Coral */
.sliderUnit.two .slideItemUnit:nth-child(20) .appInitials {background-color: #BA68C8;} /* Light Purple */
.sliderUnit.two .slideItemUnit:nth-child(21) .appInitials {background-color: #7986CB;} /* Soft Blue */
.sliderUnit.two .slideItemUnit:nth-child(22) .appInitials {background-color: #D4E157;} /* Citrus Green */
.sliderUnit.two .slideItemUnit:nth-child(23) .appInitials {background-color: #FFA726;} /* Bright Orange */
.sliderUnit.two .slideItemUnit:nth-child(24) .appInitials {background-color: #FF4081;} /* Hot Pink */
.sliderUnit.two .slideItemUnit:nth-child(25) .appInitials {background-color: #26A69A;} /* Aquatic Green */
.sliderUnit.two .slideItemUnit:nth-child(26) .appInitials {background-color: #7B1FA2;} /* Dark Purple */
.sliderUnit.two .slideItemUnit:nth-child(27) .appInitials {background-color: #FFA07A;} /* Salmon */
.sliderUnit.two .slideItemUnit:nth-child(28) .appInitials {background-color: #40C4FF;} /* Bright Cyan */
.sliderUnit.two .slideItemUnit:nth-child(29) .appInitials {background-color: #64B5F6;} /* Light Sky Blue */
.sliderUnit.two .slideItemUnit:nth-child(30) .appInitials {background-color: #81C784;} /* Pale Green */
.sliderUnit.two .slideItemUnit:nth-child(31) .appInitials {background-color: #FFCDD2;} /* Light Coral */
.sliderUnit.two .slideItemUnit:nth-child(32) .appInitials {background-color: #90CAF9;} /* Powder Blue */
.sliderUnit.two .slideItemUnit:nth-child(33) .appInitials {background-color: #F48FB1;} /* Light Pink */
.sliderUnit.two .slideItemUnit:nth-child(34) .appInitials {background-color: #C5E1A5;} /* Light Lime */
.sliderUnit.two .slideItemUnit:nth-child(35) .appInitials {background-color: #FFF176;} /* Soft Yellow */
.sliderUnit.two .slideItemUnit:nth-child(36) .appInitials {background-color: #A5D6A7;} /* Mint Green */
.sliderUnit.two .slideItemUnit:nth-child(37) .appInitials {background-color: #FFAB91;} /* Peach */
.sliderUnit.two .slideItemUnit:nth-child(38) .appInitials {background-color: #B39DDB;} /* Lavender */
.sliderUnit.two .slideItemUnit:nth-child(39) .appInitials {background-color: #80DEEA;} /* Soft Aqua */
.sliderUnit.two .slideItemUnit:nth-child(40) .appInitials {background-color: #E57373;} /* Pinkish Red */
.sliderUnit.two .slideItemUnit:nth-child(41) .appInitials {background-color: #AED581;} /* Olive Green */
.sliderUnit.two .slideItemUnit:nth-child(42) .appInitials {background-color: #FFB74D;} /* Light Orange */
.sliderUnit.two .slideItemUnit:nth-child(43) .appInitials {background-color: #CE93D8;} /* Mauve */
.sliderUnit.two .slideItemUnit:nth-child(44) .appInitials {background-color: #81D4FA;} /* Sky Blue */
.sliderUnit.two .slideItemUnit:nth-child(45) .appInitials {background-color: #FF8A65;} /* Soft Coral */
.sliderUnit.two .slideItemUnit:nth-child(46) .appInitials {background-color: #9575CD;} /* Medium Purple */
.sliderUnit.two .slideItemUnit:nth-child(47) .appInitials {background-color: #FF5252;} /* Bright Coral */
.sliderUnit.two .slideItemUnit:nth-child(48) .appInitials {background-color: #FF4081;} /* Hot Pink */
.sliderUnit.two .slideItemUnit:nth-child(49) .appInitials {background-color: #AB47BC;} /* Rich Purple */
.sliderUnit.two .slideItemUnit:nth-child(50) .appInitials {background-color: #1DE9B6;} /* Aquamarine */


.overflowOff {
    overflow: hidden;
}

.featureIconRow {
    gap:20px;
    display:flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.featureIconItem {
    width:60px;
    height:60px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius:12px;
    background-color:#eeeeee;
}

.featureIconIco {
    width:20px;
    height:20px;
    object-fit: contain;
}

.mainFeatureItem {
    display:flex;
    /* padding-top:80px; */
    border-radius:12px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.mfSection {
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 200px 200px;
    border-bottom:1px solid #eeeeee;
    background-image:url('../Assets/img/landing-pages/flowers.png');
}

.mfiTitle {
    font-size:52px;
    font-weight:700;
    text-align: center;
}

.mfiSubline {
    text-align:center;
    padding:40px 0px;
}

.protrudingHeaderIco {
    width: 70%;
    display: flex;
    box-sizing: border-box;
    border: 1px solid #cbcbce;
    border-bottom-width:0px;
    padding: 12px 12px 0px 12px;
    border-radius:12px 12px 0px 0px;
    background-color: rgba(0, 0, 0, 0.06);
}

.protrudingHeaderIco img {
    height:100%;
    border-radius:12px 12px 0px 0px;
    border:1px solid #cbcbce;
    border-bottom-width:0px;
}

.elementsBox {
    gap: 20px;
    flex: 1;
    display: flex;
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 300px;
}

.elementItem {
    width: 150px;
    height: 120px;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border:1px solid #cfd1d4;
}

.ftBig {
    width: 100%;
    height: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
    border-radius: 12px;
    margin-bottom:20px;
}

.ftBigIco {
    height:80%;
    object-fit: contain;
    object-position: 0px 0px;
    border-radius:12px;
    box-shadow:0px 3px 6px rgba(0,0,0,0.10);
}

.ftBigAdjust {
    width:80%;
    object-fit: cover;
}

.aCenter {
    align-items: center;
}

.ftMarketing {
    gap:15px;
    display:flex;
    flex-direction: column;
}

.ftmItem {
    padding:20px 40px 20px 55px;
    border-radius:12px;
    position:relative;
    background-color:#FFFFFF;
    box-shadow:0px 3px 6px rgba(0,0,0,0.10);
}

.ftmItem::before {
    content:'';
    width:20px;
    height:20px;
    border-radius:999px;
    background-color:#eeeeee;
    position:absolute;
    top:0px;
    bottom:0px;
    left:20px;
    margin:auto;
}

.ftmItem::after {
    content:'';
    width:10px;
    height:10px;
    border-radius:999px;
    background-color:#828282;
    position:absolute;
    top:0px;
    bottom:0px;
    left:25px;
    margin:auto;
}

.planBox {
    padding:50px;
    margin:0px 40px;
    border-radius:12px;
    border:1px solid #eeeeee;
    background-color: #ffffff;
}

.planItem {
    padding:40px;
    border-radius:12px;
    background-color:#f2f2f2;
}

.planItem .featureIconItem {
    background-color:#FFFFFF;
}

.planItem .fTitle {
    padding:10px 0px;
}

.planAction {
    display:flex;
    align-items: center;
    justify-content: center;
    padding-top:50px;
}

.tLeft {
    text-align: left;
}

.ctaBox {
    padding:40px;
    margin:0px 40px;
    border-radius:12px;
    background-color:#f2f2f2;
    /* background-color:#4CAF50; */
}

.ctaItem {
    gap:12px;
    /* color:#FFFFFF; */
    display:flex;
    flex-direction: column;
}

.footerWrapper {
    /* border-radius:12px; */
    margin:100px 40px 100px 40px;
    /* background-color:#f2f2f2; */
}

.footerBox {
    gap:25px;
    display:flex;
    flex-direction: column;
}

.footerHead {
    font-weight:600;
}

.footerAdjust {
    width:40%;
    flex:initial;
}

.jSpaceBetween {
    justify-content: space-between;
}

.flexInitial {
    flex:initial;
}

.mtBottomFooter {
    margin-top:80px;
    margin-bottom:120px;
}

.pricingBox {
    padding: 85px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
}

.pricingAction {
    padding:8px;
    display:flex;
    flex-direction: row;
    align-items: center;
    border-radius:999px;
    background-color:#ededed;
}

.paItem {
    padding:12px 18px;
    border-radius:999px;
}

.paItem.active {
    background-color:#FFFFFF;
}

.pricingContainer {
    padding:40px;
    border-right: 1px solid #cfd1d4;
    border-right: 1px solid #eeeeee;
}

.wrapper .rowItem:nth-child(3) .pricingContainer {
    border-right-width: 0px;
}



.pcPlan {
    font-size:18px;
    font-weight:600;
    margin-bottom:20px;
}

.pcCost {
    font-size:32px;
    font-weight:600;
}

.pcCost span {
    font-size:14px;
}

.pcDesc {
    color:#bdbdbd;
}

.pcBody {
    gap:20px;
    display:flex;
    flex-direction: column;
    padding:40px 0px;
}

.pcItem {
    font-weight:600;
    padding-left: 40px;
    position: relative;
}

.pcItem::before {
    top:0px;
    left:0px;
    bottom:0px;
    width:20px;
    height:20px;
    margin:auto;
    content: '';
    position:absolute;
    border-radius:999px;
    background-color:#eeeeee;
}

.pcItem::after {
    top:0px;
    left:0px;
    bottom:0px;
    width:20px;
    height:20px;
    margin:auto;
    content: '';
    position:absolute;
    background-size:16px 16px;
    background-repeat: no-repeat;
    background-position: center;
    background-image:url('../Assets/img/landing-pages/check.png');
}



.pcFooter {
    display:flex;
    flex-direction: column;
}

.pcAction {
    font-size:18px;
    font-weight:600;
    padding:15px 30px;
    text-align: center;
    border-radius:999px;
    background-color:#eeeeee;
}

.pcFooter {
    margin-top:30px;
}


.faqBox {
    gap:20px;
    width:60%;
    margin:auto;
    display:flex;
    flex-direction: column;
}

.faqTitle {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    margin: 40px 0px;
}

.faqItem {
    flex:1;
    /* font-size:18px; */
    display:flex;
    padding: 40px 40px;
    border-radius:12px;
    flex-direction: column;
    position:relative;
    background-color:#eeeeee;
    font-family:'Lexend'
    /* border-bottom:1px solid #eeeeee; */
}

.faqItem::before {
    top:0px;
    right:40px;
    bottom:0px;
    width:20px;
    height:20px;
    margin:auto;
    content:'';
    position:absolute;
    background-size:20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-image:url('../Assets/img/landing-pages/cdown.png');
}

.faqAnswer {
    display:none;
}

.faqItem.active .faqAnswer {
    display: block
}

.frontWrapper {
    flex:1;
    display:flex;
    height:100vh;
    flex-direction: row;
}

.frontWrapper a {
    color: #212529;
}

.onboardingWrapper {
    flex:1;
    display:flex;
    position: relative;
    flex-direction: column;
}

.onboardingLeft form {
    flex:1;
    display:flex;
    justify-content: center;
    /* border-radius:12px; */
    /* border: 1px solid #dbdce3; */
    /* box-shadow: 0 1px 1px rgba(0, 0, 0, .06); */
}

.onBoardingColumn {
    flex:1;
    gap:12px;
    display:flex;
    padding:20px 40px;
    flex-direction: column;
}

.onboardingLeft {
    flex:1;
    gap:12px;
    display:flex;
    padding:40px;
    flex-direction: column;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 1px, transparent 1px), linear-gradient(to right, rgba(0, 0, 0, 0.05) 1px, transparent 1px), linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    background-size: 250px 250px, 250px 250px, 100% 100%;
    background-blend-mode: normal, normal, lighten;
}

.enrollPlanOptions {
    flex:1;
    gap:20px;
    display:flex;
    padding: 40px;
    overflow:hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color:#212121;
    border-left: 1px solid #dbdce3;
}

.enrollPlanOptions h4 {
    color:#FFFFFF;
}

.epoWrapper {
    gap:20px;
    display:flex;
    flex-direction: column;
}

.epoItem {
    gap:12px;
    padding:20px;
    display:flex;
    border-radius:12px;
    flex-direction: column;
    background-color:#3a3a3a;
    border:1px solid #dbdce3;
}

.epoItem.active {
    border-color:#212121;
}

.epoHead {
    gap:200px;
    display:flex;
    flex-direction: row;
}

.epoHeadDecor {
    width:15px;
    height:15px;
    border-radius:999px;
    position:relative;
    background-color:#eeeeee;
    display:flex;
    align-items: center;
    justify-content: center;
}

.epoMulti {
    display:flex;
}

.epoMultiItem {
    flex:1;
    display:flex;
    flex-direction: column;
}

.epoLabel {
    font-size:12px;
    color:#FFFFFF;
}

.epoValue {
    color:#FFFFFF;
    font-weight:500;
}

.epoItem.active .epoHeadDecor::before {
    width:50%;
    height:50%;
    content:'';
    position: absolute;
    border-radius:999px;
    background-color:#212121;
}

.obBox {
    gap:20px;
    padding:40px;
    display:flex;
    flex-direction: column;
    background-color: #ffffff;
}

.obItem {
    gap:8px;
    display:flex;
    flex-direction: column;
}

.obItem input {
    flex:1;
    display:flex;
    padding:10px 15px;
    border-radius:8px;
    border: 1px solid #dbdce3; 
    box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.bgDecorMain {
    width:800px;
    height:800px;
    position:absolute;
    background-color:#eeeeee;
    right:-400px;
    top:-200px;
    transform:rotate(45deg);
    border-radius:80px;
    display: none;
}

.frontHeader {
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.onboardingBox {
    flex:1;
    display:flex;
    margin:auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.onboardingBox h2 {
    font-size:32px;
    margin-bottom:30px;
}

.frontDarkButton {
    flex: 1;
    width: 100%;
    padding: 14px;
    display: flex;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: #212121;
    border: 1px solid #dbdce3;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.onboardingIco {
    top: -20%;
    right: -50%;
    width: 100%;
    opacity: 0.25;
    position: absolute;
}

.enrollMain {
    font-size:18px;
    font-weight:500;
    color:#ffffff;
}

.enrollContextArea {
    color: #FFFFFF;
    padding: 20px;
    border-radius: 12px;
    border: 1px solid #474747;
    background-color: #2f2f2f;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
    max-width: 75%;
}

.epoContentBox {
    gap:20px;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index:999;
}
.xoloHeader {
  display: flex;
  flex-direction: column;
  background-color:#FFFFFF;
}

.xoloHeaderTop {
  height:76px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 20px;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
}

.xoloLogo {
  color: #000000;
  font-size: 24px;
  font-weight: 800;
  display:flex;
  flex-direction: row;
  line-height:20px;
}

a.xoloLogo:hover {
  color: #000000;
}

.xoloLogo span {
  color: #000000;
}

.xoloMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
  display:none;
}

.xoloMenuItem {
  font-size: 16px;
  font-weight: 700;
  color: #A0A0A0;
  margin: 0px 20px;
}

.xoloPush {
  gap:15px;
  display: flex;
  flex-direction: row;
}

.xoloDeploy {
  height: 45px;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #e4e4e4;
  border-radius: 6px;
}

.xoloDeployLabel {
  color: #ffffff;
  font-size: 14px;
}

.xoloDeployIco {
  width: 30px;
  height: 30px;
  border-radius: 999px;
  background-color: #454545;
  display:none;
}

.xoloDeployDeviceIco {
  width:20px;
  height:20px;
  object-fit:contain;
  margin:0px 4px;
  opacity:0.4;
}

.currentSettings {
  width:45px;
  height:45px;
  display:flex;
  border-radius:8px;
  align-items: center;
  justify-content: center;
  border:1px solid #e8e8e8;
}

.currentSettingsIco {
  width:20px;
  height:20px;
  opacity:0.4;
}

.xoloStatus {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: #00de11;
  margin: 0px 15px;
}

.xoloStatusInner {
  width: 65%;
  height: 65%;
  border-radius: 999px;
  background-color: #2eff3d;
}

.xoloRepublish {
  height: 45px;
  padding: 0px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #397dff;
  border-radius: 6px;
}

.xoloRepublishLabel {
  color: #ffffff;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 15px;
}

.xoloRepublishIco {
  width: 30px;
  height: 30px;
  border-radius: 999px;
  background-color: #c63cff;
  display:none;
}

.xoloHeaderBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  margin: 0px 320px;
  display:none;
}
.xoloHeaderAction {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.xoloBionic {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0px 15px;
}

.xoloBionicIco {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 1px;
  background-color: #444444;
}

.xoloBionicLabel {
  color: #a0a0a0;
}

.xoloPlatform {
  display: flex;
  height: 30px;
  flex-direction: row;
  align-items: center;
  border-radius: 999px;
  background-color: #ffffff;
  padding: 0px 6px;
}
.xoloPlatformItem {
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
}
.xoloPlatformItem.active {
  color: #ffffff;
  background-color: #b400ff;
}

.navbar-expand-lg .navbar-collapse {
  flex: initial;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-lg,
.navbar > .container-md,
.navbar > .container-sm,
.navbar > .container-xl,
.navbar > .container-xxl {
  justify-content: center;
}

.appxoloBuilder {
  flex:1;
  display: flex;
  position: relative;
  background-image: radial-gradient(circle, #e4e4e4 1px, #f4f4f4 1px); 
  background-size: 15px 15px;
}

.revealOffset {
  margin-top:-14px;
}

.builderPane {
  flex: 1;
  width: 322px;
  max-width: 322px;
  height:calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  background-color:#FFFFFF;
  border-right:1px solid #e8e8e8;
}

.builderPanel {
  display:flex;
  flex-direction: column;
}

.builderPanel.three {
  flex:1;
  overflow-y:hidden;
}

.builderPanel.three .builderHierarchy {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.builderPanel.three .builderComboBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.builderTools {
  border-radius: 6px;
  background-color: #ffffff;
}

.builderScreens {
  
}

.builderActions {
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.currentAppIco {
  width:45px;
  height:45px;
  border-radius:8px;
}

.currentActiveUsers {
  display:flex;
  align-items: center;
  margin-right:20px;
}

.cauItem {
  width:42px;
  height:42px;
  display:flex;
  font-size:12px;
  font-weight:500;
  margin-right:-20px;
  border-radius:8px;
  color:rgba(0,0,0,0.40);
  align-items: center;
  justify-content: center;
  border: 3px solid #FFFFFF;
  background-color: #f2f2f2;
  outline: 1px solid #e8e8e8;
  text-transform:uppercase;
}

.builderOptions {
  width: 270px;
}
.builderCanvas {
  flex: 1;
  max-width: 100%;
  overflow: auto;
  position:relative;
}

.builderElements {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
}

.builderElementItem {
  width: calc(50% - 16px);
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  border-radius: 8px;
  font-size: 14px;
  color: #bcbcbc;
  margin: 8px;
  box-sizing: border-box;
}

.builderElementIcon {
  width: 30px;
  height: 30px;
  margin-bottom: 6px;
  object-fit: contain;
  filter:invert(1);
}

.builderElementsLabel {
  font-size: 14px;
  font-weight: 600;
  padding-left: 16px;
  padding-top: 16px;
  display: flex;
  gap: 20px;
  cursor: pointer;
}

.builderElementsLabel .active {
  color: blue
}

.builderScreenBox {
  gap:12px;
  display:flex;
  padding:12px;
  margin:12px 16px;
  border-radius:12px;
  flex-direction: column;
  background-color:#f3f3f3;
}

.screenItemGroup {
  display:flex;
  flex-direction:column;
}

.screenSelectorItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  position:relative;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.urlSet .screenSelectorItem .optionInputIconBoxField {
  margin-left:20px;
  padding-left:0px;
  border-radius:0px;
}

.urlSet .screenSelectorItem::before {
  content:'/';
  margin-left:10px;
  position:absolute;
}

.screenApply {
  font-size: 12px;
  color: initial;
  padding: 4px 10px;
  margin-right: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dbdce3;
  background-color: #f8f8f8;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.screenItemAction .defaultButton {
  font-size:12px;
}

.screenSelectorItem.rte {
  display: block;
}

.builderScreensLabel {
  display:flex;
  font-size: 14px;
  font-weight: 600;
  margin-top:8px;
  padding:0px 16px;
  align-items: center;
  justify-content: space-between;
}

.builderScreensLabel span {
  font-size:12px;
  font-weight:400;
}

.paramAction {
  width:20px;
  height:20px;
  font-weight:500;
  display:flex;
  align-items: center;
  justify-content: center;
  position:absolute;
  right:12px;
  background-color:#f3f3f3;
  border-radius:4px;
}

.screenItemAction {
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.builderScreenList {
  padding:16px;
  display: grid;
  grid-auto-rows: 45px; 
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  gap: 10px; /* Gap between grid items */
  /* height: 232px; */
  overflow-y: auto;

  --sb-track-color: #ffffff;
  --sb-thumb-color: #e5e5e5;
  --sb-size: 8px;
}

.builderScreenList::-webkit-scrollbar {
  width: var(--sb-size);
}

.builderScreenList::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 2px;
}

.builderScreenList::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 0px;
}

.screenItem {
  /* margin:8px;
  display:flex;
  color:initial;
  border-radius:4px;
  width:calc(50% - 16px); */

  display: flex;
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid #dfdfdf;
  height: 45px; /* Fixed height */
  word-wrap: break-word; /* Ensure long text wraps */
  text-align: left; /* Center text inside the box */
  overflow: hidden; /* Avoid overflow */
  border-radius:6px;
}

.addScreen {
  justify-content: center;
}

.screenItemLabel {
  color: #000000;
  padding: 0px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.builderComboBoxHeader {
  display:flex;
  flex-direction:row;
  padding:0px 8px 12px 8px;
}

.builderComboBoxHeaderItem {
  padding:8px;
  font-size: 14px;
}

.builderComboBoxHeaderItem.active {
  font-weight: 600;
}

.builderHierarchyLabel {
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  padding-top: 16px;
  padding-left: 16px;
  padding-bottom:8px;
  border-top: 1px solid #e8e8e8;
}

.builderHierarchyList {
  overflow-x:auto;
  /* max-height:calc(100vh - 799px); */
  --sb-track-color: #ffffff;
  --sb-thumb-color: #e5e5e5;
  --sb-size: 8px;
}

.builderHierarchyList::-webkit-scrollbar {
  width: var(--sb-size);
}

.builderHierarchyList::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 2px;
}

.builderHierarchyList::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 4px;
}

.hierarchyLeft {
  flex:1;
  display:flex;
  align-items: center;
}

.hierarchyIco {
  width: 14px;
  height: 14px;
  object-fit: contain;
  margin-right: 14px;
}

.hierarchyItemUnit {
  color:#9d9d9d;
  border-radius:8px;
  padding: 8px 0px;
  margin-bottom:4px !important;

  /* background-color: #f7f7f7; */
  /* border:1px solid #dfdfdf; */
  /* padding:12px 14px; */
}

.hierarchyItemUnit img {
  opacity: 0.6;
}

.hierarchyItemUnit.active {
  color:#212121;
  /* background-color: #212121; */
}

.hierarchyItemUnit.active img {
  opacity: 1;
}

.hierarchyItemUnit.active {
  opacity: 1;
}

.builderOptions {
  flex: 1 1;
  width: 375px;
  max-width: 375px;
  display: flex;
  flex-direction: column;
}

.optionsPanel {
  padding: 8px;
  border-radius: 0px;
  background-color: #ffffff;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
  max-height: calc(100vh - 95px);
  overflow-y: auto;
  border-left:1px solid #e8e8e8;
}

.optionsHeader {
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionsElementName {
  font-size: 16px;
  color: #212121;
  font-weight: 700;
}

.optionsElementName span {
  font-size: 14px;
  color: #585858;
  font-weight: 400;
}

.optionsElementMeta {
  font-size: 12px;
  font-weight: 700;
  margin-left: 10px;
  border-radius: 2px;
  padding: 0px 10px;
  background-color: #f8f8f8;
}

.optionsElementMeta span {
  font-size: 14px;
  font-weight: 400;
  color: #b1b1b1;
}

.optionItem {
  padding: 8px;
}

.optionItemInner {
  border-radius: 8px;
  background-color: #f3f3f3;
  /* background-color: #f7f7f7; */
}

.optionItemHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.optionItemLabel {
  font-weight: 700;
}

.optionItemDecor {
  width: 20px;
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.optionItemDecorIco {
  width:12px;
  height:12px;
}
.optionItemDecorLine.one {
  width: 8px;
  height: 2px;
  border-radius: 0px;
  background-color: #ffffff;
  position: absolute;
}
.optionItemDecorLine.two {
  width: 8px;
  height: 2px;
  border-radius: 0px;
  background-color: #ffffff;
  position: absolute;
  display: none;
  transform: rotate(90deg);
}

.optionItemBody {
  flex:1;
  padding: 7px;
  min-width:0;
}

.optionItemRow {
  display: flex;
  flex-direction: row;
}

.optionItemBox {
  flex: 1;
  display: flex;
  padding: 7px;
  flex-direction: column;
}

.optionInputIconBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.optionInputIconBoxField {
  flex: 1;
  width: 100%;
  height: 36px;
  display: flex;
  padding: 0px;
  color: #000000;
  font-size:12px;
  font-weight: 500;
  padding-left: 10px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  border-width: 0px;
  position:relative;
  background-color: transparent;
}

.optionInputIconBoxSpacingField {
  flex: 1;
  width: 100%;
  height: 36px;
  display: flex;
  padding: 0px;
  font-size:12px;
  color: #000000;
  font-weight: 500;
  padding-left: 4px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  border-width: 0px;
  outline: 0px;
  background-color: transparent;
}

.optionInputIconBoxText {
  flex: 1;
  width: 100%;
  display: flex;
  padding: 0px;
  color: #939393;
  font-size: 14px;
  font-weight: 400;
  padding-left: 10px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  border-width: 0px;
  background-color: transparent;
}

.optionInputIconBoxIco {
  width: 14px;
  height: 14px;
  margin-left: 10px;
  object-fit: contain;
}

.optionInputIconBoxArrowIco {
  width: 10px;
  height: 10px;
  margin-left: 6px;
  object-fit: contain;
}

.oibLabel {
  font-size:12px;
  color:#9d9d9d;
  font-weight:500;
  margin-bottom:8px;
}

.inputOptionWrapper .optionItemBox > div {
  border-radius:8px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.inputOptionWrapper .optionItemBox {
  position: relative;
}

.inputOptionWrapper .optionItemBox .oibLabel {
  top:17px;
  left:21px;
  z-index:1;
  box-shadow:none;
  position:absolute;
  margin-bottom:0px;
  pointer-events: none;
}

.inputOptionWrapper .optionItemBox > div .optionInputIconBox {
  box-shadow:none;
  padding: 28px 0px 1px 0px;
}

.inputOptionWrapper .optionItemBox > div .optionInputIconBoxField {
  height:28px;
  font-size:14px;
  font-weight:400;
  padding-top:1px;
  padding-left:14px;
  align-items: flex-start;
}

.inputOptionWrapper .optionItemBox > div .custom-select {
  
}

.position-absolute {
  position: absolute;
}



.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}

.rotate45 {
  transform: rotate(45deg);
}

.rotate135 {
  transform: rotate(135deg);
}

.rotate225 {
  transform: rotate(225deg);
}

.rotate315 {
  transform: rotate(315deg);
}

.cOrange {
  color:#ED8849
}

.cGreen {
  color:#31af26;
}

.advancedRadioItem {
  color: #939393;
  border-radius: 8px;
  background-color: #f7f7f7;
  border: 1px solid #dadada;
}

.advancedRadioLabel {
  margin: 0px;
  height: 37px;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.advancedRadioItem.active {
  color: #b400ff;
  border-color: #2d3035;
}

.advancedRadioItem.darkActive {
  border-color: #2d3035;
  background-color: #2d3035;
}
.advancedRadioItem.darkActive .advancedMultiLabel {
  color: #ffffff;
}
.advancedRadioItem.darkActive .containerDecor {
  border-color: #ffffff;
  background-color: #ffffff;
}

.horizontalOptions {
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
}

.horizontal {
  height: 10px;
  border-radius: 2px;
  background-color: #dbdbdb;
}

.horizontal.one {
  width: 2px;
  max-width: 2px;
}

.horizontal.two {
  width: 6px;
  max-width: 30px;
  margin: 0px 2px;
}

.horizontal.three {
  width: 2px;
  max-width: 2px;
}

.horizontalArrowIco {
  width:10px;
  height:10px;
  opacity:0.6;
  margin:0px 4px;
}

.jLeft .horizontal.three {
  display:none;
}

.jLeft .horizontalArrowIco.one , .jLeft .horizontalArrowIco.two , .jLeft .horizontalArrowIco.four {
  display:none;
}

.jRight .horizontalArrowIco.two , .jRight .horizontalArrowIco.three , .jRight .horizontalArrowIco.four {
  display:none;
}

.jRight .horizontalArrowIco {
  transform:rotate(180deg);
}

.jSpaceBetween .horizontalArrowIco.one , .jSpaceBetween .horizontalArrowIco.four {
  display:none;
}

.jSpaceBetween .horizontalArrowIco.three {
  transform:rotate(180deg);
}

.jCenter .horizontalArrowIco.one , .jCenter .horizontalArrowIco.four {
  display:none;
}

.jCenter .horizontalArrowIco.two {
  transform:rotate(180deg);
}

.jRight .horizontal.one {
  display:none;
}

.jCenter .horizontal.one , .jCenter .horizontal.three {
  display:none;
}

.verticalOptions {
  padding: 10px;
  padding-bottom:0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.verticalArrowIco {
  width: 10px;
  height: 10px;
  opacity: 0.6;
  transform:rotate(90deg);
}

.vertical {
  width: 10px;
  border-radius: 2px;
  background-color: #dbdbdb;
}

.vertical.one {
  height: 7px;
  max-height: 2px;
}

.vertical.two {
  height: 10px;
  max-height: 6px;
  margin: 2px 0px;
  display:none;
}

.vertical.three {
  height: 2px;
  max-height: 2px;
  display: none;
}

.jStart .verticalArrowIco.one , .jStart .verticalArrowIco.two , .jStart .verticalArrowIco.three {
  display:none;
}

.jEnd .verticalArrowIco.two , .jEnd .verticalArrowIco.three , .jEnd .verticalArrowIco.four {
  display:none;
}

.jEnd .verticalArrowIco.one {
  transform:rotate(270deg);
}


.oneOnly, .twoOnly, .threeOnly, .fourOnly {
  display:none;
}


.jSpaceBetween .verticalArrowIco.one , .jSpaceBetween .verticalArrowIco.two , .jSpaceBetween .verticalArrowIco.three , .jSpaceBetween .verticalArrowIco.four {
  display:none;
}

.jSpaceBetween .verticalArrowIco.twoOnly {
  display:flex;
}

.jSpaceBetween .verticalArrowIco.threeOnly {
  display:flex;
  transform:rotate(270deg);
  margin-top:-6px;
}

.jSpaceBetween .verticalArrowIco.three {
  transform:rotate(270deg);
}

.jSpaceBetween .vertical.three {
  display: flex;
  min-height: 2px;
}

.jSpaceBetween .vertical.one , .jSpaceBetween .vertical.two , .jSpaceBetween .vertical.three {
  display:none;
}

.nvoItem .verticalOptions {
  padding-bottom:10px;
}

.nvoItem .verticalOptions.jSpaceBetween {
  padding: 9px 10px;
}




.jCenter .verticalArrowIco.one , .jCenter .verticalArrowIco.two , .jCenter .verticalArrowIco.three , .jCenter .verticalArrowIco.four {
  display:none;
}

.jCenter .vertical.one {
  display: none;
}

.jCenter .verticalArrowIco.one {
  transform:rotate(270deg);
}

.jCenter .verticalArrowIco.twoOnly {
  display:flex;
  transform:rotate(270deg);
}

.jCenter .verticalArrowIco.threeOnly {
  display:flex;
}
.nvoItem .verticalOptions.jCenter {
  padding: 6px 10px 6px 10px;
}




.nvoItem .advancedMultiLabel span {
  display:none;
}

.flexOptionsItem {

}
.flexOptionsItem .advancedMultiLabel {
  font-size: 10px;
  padding: 0px 9px;
  margin-top: -6px;
  margin-bottom: 4px;
  text-align:center;
}

.nvoItem .advancedMultiLabel {
  font-size: 10px;
  padding: 0px 9px;
  margin-top: -6px;
  margin-bottom: 4px;
  text-align: center;
}

.foiUnit .optionItemBox:nth-child(1) .advancedMultiLabel {
  text-align:left;
}

.fRow {
  display: flex;
  flex-direction: row;
}

.fColumn {
  display: flex;
  flex-direction: column;
}

.aLeft {
  align-items: left;
}

.aRight {
  align-items: right;
}
.aCenter {
  align-items: center;
}

.aEnd {
  align-items: end;
}

.jLeft {
  justify-content: left;
}
.jRight {
  justify-content: right;
}
.jSpaceBetween {
  justify-content: space-between;
}
.jSpaceEvenly {
  justify-content: space-evenly;
}
.jCenter {
  justify-content: center;
}
.jStart {
  justify-content: flex-start !important;
}
.jEnd {
  justify-content: flex-end;
}

.currentAppMeta {
  gap: 15px;
  display:flex;
  flex-direction:row;
  align-items: center;
}

.advancedMultiLabel {
  font-size: 13px;
  color: #767676;
  font-weight: 400;
  padding: 8px;
}

.advancedMultiLabel span {
  font-size: 10px;
  color: #000000;
  font-weight: 400;
}

.advancedRadioItem.active .advancedMultiLabel {
  color: #b400ff;
}

.advancedRadioItem.active .advancedMultiLabel span {
  color: #000000;
}

.optionItemLabelCombo {
  flex:1;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.optionHeadTabs {
  display: flex;
  flex-direction: row;
  border: 0px solid #e2e2e2;
  background-color: #eaeaea;
  border-radius: 4px;
  margin-left: 8px;
  padding: 4px;
}
.optionHeadTabItem {
  padding: 0px 6px;
  border-radius: 4px;
  font-size: 12px;
  color: #8d8d8d;
}
.optionHeadTabItem.active {
  color: #ffffff;
  background-color: #212121;
}

.optionDatabaseSelector {
  padding: 16px;
  margin-top: 14px;
  background-color: #acacac;
  box-shadow: inset 0px 3px 6px rgba(0, 3, 6, 0.16);
}

.optionDatabaseSelectorItem {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 3, 6, 0.16);
}

.optionDatabaseSelectorLeft {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.optionDatabaseSelectorIco {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  margin: 0px 12px;
  background-color: #eaeaea;
}

.optionDatabaseSelectorIco div {
  width: 10px;
  height: 10px;
  border-radius: 999px;
  background-color: #f7f7f7;
}

.optionDatabaseSelectorInfo {
  flex: 1;
  display: flex;
  padding: 6px 0px;
  flex-direction: column;
}

.optionDatabaseSelectorSubline {
  font-size: 12px;
  color: #00ceff;
  font-weight: 700;
}

.optionDatabaseSelectorLabel {
  font-size: 14px;
  color: #939393;
}

.optionDatabaseSelectorChevron {
  width: 12px;
  height: 12px;
  object-fit: contain;
  margin-right: 16px;
  margin-bottom: 10px;
}

.optionDatabaseBoxWrapper {
  flex: 1;
  padding: 7px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
}
.optionDatabaseBox {
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 3, 6, 0.16);
}
.optionDatabaseBoxLabel {
  padding: 12px 16px;
  font-size: 14px;
  color: #4d4d4d;
  font-weight: 700;
}

.optionDatabaseFilterItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px;
  background-color: #f3f3f3;
  border-top: 1px solid #f7f7f7;
}

.optionDatabaseFilterClose {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border-radius: 999px;
  position: relative;
  margin: 0px 10px 0px 0px;
  display: none;
}

.optionDatabaseFilterCloseLine {
  width: 1px;
  height: 10px;
  border-radius: 999px;
  background-color: #afafaf;
  position: absolute;
}

.optionDatabaseFilterCloseLine.one {
  transform: rotate(45deg);
}
.optionDatabaseFilterCloseLine.two {
  transform: rotate(-45deg);
}

.optionDatabaseFilterContext {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.optionDatabaseFilterKey {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 14px;
  display: none;
}

.optionDatabaseFilterKeyLabel {
  font-size: 14px;
  font-weight: 700;
  color: #b400ff;
}

.optionDatabaseFilterKeyIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 6px;
}

.optionDatabaseFilterMeta {
  margin: 7px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 1px solid #f7f7f7;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.optionDatabaseFilterMetaParams {
  display: flex;
  flex-direction: row;
  border-bottom: 0px solid #ffffff;
}

.optionDatabaseFilterMetaColumn {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f7f7f7;
  padding: 6px 12px;
}

.optionDatabaseFilterMetaRequirement {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 0px solid #ffffff;
  padding: 6px 12px;
}

.optionDatabaseFilterMetaRequirementLabel {
  flex: 1;
  display: flex;
  font-size: 14px;
  color: #9f9f9f;
}

.optionDatabaseFilterMetaColumnLabel {
  flex: 1;
  display: flex;
  font-size: 14px;
  color: #b400ff;
}

.optionDatabaseFilterMetaRequirementIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 6px;
}

.optionDatabaseFilterMetaType {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  display: none;
}

.optionDatabaseFilterMetaTypeLabel {
  font-size: 14px;
  color: #9f9f9f;
  display: flex;
  flex: 1;
}

.optionDatabaseFilterMetaTypeIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 6px;
}

.optionDatabaseFilterMetaQuery {
  position: relative;
  background-color: #f7f7f7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.optionDatabaseFilterMetaQueryInput {
  flex: 1 1;
  width: 100%;
  height: 64px;
  display: flex;
  font-size: 14px;
  border-width: 0px;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  resize: none;
  outline: 0px;
  background-color: transparent;
}

.optionDatabaseFilterAddMore {
  display: flex;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 400;
  color: #31af26;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f7f7f7;
}

.optionDatabaseOrder {
}

.optionDatabaseOrderItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px;
  border-top: 0px solid #f7f7f7;
}

.optionDatabaseOrderContext {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.optionDatabaseOrderKey {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 8px;
}

.optionDatabaseOrderKeyLabel {
  font-size: 14px;
  font-weight: 700;
  color: #b400ff;
}

.optionDatabaseOrderKeyIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 6px;
}

.optionDatabaseSortKey {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 8px;
}
.optionDatabaseSortKeyLabel {
  flex: 1;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  color: #939393;
}
.optionDatabaseSortKeyIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 6px;
}

.optionDatabasePagination {
  padding: 0px;
}

.optionDatabasePaginationItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 0px;
  border-top: 1px solid #f7f7f7;
}
.optionDatabaseRadio {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border-radius: 999px;
  position: relative;
  margin: 0px 12px 0px 16px;
}

.optionDatabaseRadioDecor {
  width: 12px;
  height: 12px;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f7f7f7;
}

.optionDatabaseRadio.active .optionDatabaseRadioDecor {
  background-color: #31af26;
}

.optionDatabasePaginationContext {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.optionDatabasePaginationKey {
  display: flex;
  margin-top: 4px;
  flex-direction: row;
  align-items: center;
}

.optionDatabasePaginationKeyLabel {
  font-size: 14px;
  font-weight: 700;
  color: #b400ff;
}

.optionDatabasePaginationMeta {
  margin-top: 0px;
}
.optionDatabasePaginationMetaInput {
  display: flex;
  flex: 1;
  width: 100%;
  height: 30px;
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  border-width: 0px;
}

.max90w {
  /* max-width: 90px; */
}
.optionDatabaseSelectChevron {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-right: 16px;
}
.pad5t {
  padding-top: 5px;
}

.optionItemListLabelContainer {
  padding:0px 8px;
  display:none;
}

.oillIco {
  width:14px;
  height:14px;
  object-fit: contain;
  filter:invert(1)
}
.containerDirectionLabel {

}

.optionColorItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.optionColorCircle {
  width: 29px;
  height: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  position: relative;
  background-color:#ffffff;
  border: 4px solid #ffffff;
  outline: 1px solid #d5d5d5;
}

.bgWhite {
  background-color: #ffffff;
}

.bgGrey {
  background-color: #e0e0e0;
}

.bgBlack {
  background-color: #000000;
}

.shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06) !important;
}

.pad6 {
  padding: 6px;
}

.pad7 {
  padding: 7px;
}

.pad8 {
  padding: 8px;
}

.panePad {
  padding: 7px;
}

.flex1 {
  flex: 1;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.br8 {
  border-radius:8px;
}

.br10 {
  border-radius: 10px;
}

.pad0v {
  padding-top: 0px;
  padding-bottom: 0px;
}

.cPurple {
  color: #b400ff;
}

.cGreen {
  color: #31af26;
}

.cDark {
  color: #171717;
}

.cGrey {
  color: #939393;
}

.fwRegular {
  font-weight: 400;
}
.fwMedium {
  font-weight: 400;
}
.fwSemiBold {
  font-weight: 700;
}

.optionCondition {
  padding: 7px;
  background-color: #d7d7d7;
  border-top: 0px solid #e8e8e8;
  border-radius: 0px 0px 10px 10px;
}

.optionConditionItem {
  padding: 7px;
}

.optionConditionItemInner {
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.optionConditionItemHead {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  position: relative;
}

.optionConditionItemContext {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.optionConditionItemMeta {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 14px;
  padding-top: 8px;
}

.optionConditionItemMetaField {
  font-size: 18px;
  font-weight: 800;
  color: #434343;
  border: none;
}

.optionConditionItemChevron {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 8px;
}

.optionConditionItemValue {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 14px;
  padding-bottom: 8px;
}

.optionConditionItemValueField {
  font-size: 14px;
  color: #939393;
  border: none;
}

.optionConditionAdd {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 8px;
  border-radius: 8px;
  background-color: #00de11;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.optionConditionAddLine {
  width: 10px;
  height: 2px;
  position: absolute;
  border-radius: 999px;
  background-color: #ffffff;
}
.optionConditionAddLine.two {
  transform: rotate(90deg);
}
.optionConditionRemove {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 12px;
  border-radius: 4px;
  background-color: #A80000;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius:8px;
}

.optionConditionRemoveLine {
  width: 8px;
  height: 1px;
  position: absolute;
  border-radius: 999px;
  background-color: #ffffff;
}

.optionConditionAdd {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 12px;
  border-radius: 4px;
  background-color: #10b507;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius:8px;
}

.optionConditionAddLine {
  width: 8px;
  height: 1px;
  position: absolute;
  border-radius: 999px;
  background-color: #ffffff;
}
.optionConditionAdd .optionConditionAddLine:nth-child(2) {
  transform:rotate(90deg);
}

.optionConditionTableSelector {
  background-color: #505050;
}

.optionConditionTableSelectorHead {
  padding: 8px 0px;
  background-color: #2f2f2f;
}

.optionConditionTableSelectorHeadSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 14px;
  justify-content: space-between;
}
.optionConditionTableSelectorHeadSelectLabel {
  font-size: 14px;
  color: #00c6ce;
}

.optionConditionTableSelectorHeadSelectIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
}
.optionConditionTableSelectorHeadSubline {
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
  padding: 0px 14px;
}

.optionConditionTableSelectorBodyList {
  padding: 7px;
  padding-top: 0px;
}
.optionConditionTableSelectorFilterItem {
  display: flex;
  padding: 7px;
  flex-direction: row;
  align-items: center;
}

.optionConditionTableFilterMeta {
  border-radius: 3px;
}

.optionConditionTableFilterMetaParams {
  display: flex;
  flex-direction: row;
  border-top: 1px solid #505050;
  border-bottom: 1px solid #505050;
}

.optionConditionTableFilterMetaRequirement {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 0px solid #505050;
  padding: 6px 12px;
}

.optionConditionTableFilterMetaRequirementLabel {
  flex: 1;
  display: flex;
  color: #939393;
  font-size: 12px;
  color: #9d9d9d;
  font-weight: 500;
}

.optionConditionTableFilterMetaRequirementIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 6px;
}

.optionConditionTableFilterMetaType {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  display: none;
}

.optionConditionTableFilterMetaTypeLabel {
  font-size: 14px;
  color: #9f9f9f;
  display: flex;
  flex: 1;
}

.optionConditionTableFilterMetaTypeIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 6px;
}

.optionConditionTableFilterMetaQuery {
  position: relative;
  /* background-color: #000000; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.optionConditionTableFilterMetaQueryInput {
  /* flex: 1; */
  width: 100%;
  /* min-height: 64px; */
  /* display: flex; */
  /* color: #ffffff; */
  resize: none;
  outline: 0px;
  font-size: 14px;
  border-width: 0px;
  padding-top: 2px;
  padding-left: 14px;
  padding-right: 14px;
  word-break: break-all;
  background-color: transparent;
}

.optionConditionTableSelectorFilterItemHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 14px 0px 14px;
}
.optionConditionTableParam {
  flex: 1;
  display: flex;
  font-size: 14px;
  color: #ffb100;
}
.optionConditionTableSelectorHeadSublineFilter {
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
  padding: 8px 14px 0px 14px;
}
.optionConditionTableSelectorFilterItemContext {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #2f2f2f;
  position: relative;

  max-width: 100%; 
  padding-bottom: 8px; 
  background-color: #ffffff; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}
.optionConditionTableSelectorFilterRemove {
  min-width: 16px;
  min-height: 16px;
  border-radius: 999px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffffff;
  display: none;
}
.optionConditionTableSelectorFilterLine {
  width: 7px;
  height: 2px;
  border-radius: 999px;
  position: absolute;
  background-color: #ff0000;
}

.optionDatabaseValueSelector {
  display: flex;
  flex-direction: column;
  background-color: #2f2f2f;
  padding: 10px 0px;
}
.optionDatabaseValueSelectorLabel {
  font-size: 10px;
  font-weight: 700;
  color: #ffffff;
  padding: 0px 14px;
}
.optionDatabaseValueSelectorSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 14px;
  background-color: #000000;
  padding: 10px 12px;
  border-radius: 10px;
}
.optionDatabaseValueSelectorSelectValue {
  flex: 1;
  display: flex;
  color: #939393;
  font-size: 14px;
}

.optionDatabaseValueSelectorSelectIco {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-left: 8px;
}

.optionConditionTableSelectorAddFilter {
  padding: 14px;
  padding-top: 0px;
}
.optionConditionTableSelectorAddFilterLabel {
  color: #ffffff;
  text-align: center;
  padding: 8px 0px;
  background-color: #2f2f2f;
  border-radius: 6px;
}

.optionDatabaseTextBox {
  border-radius: 8px;
  padding-bottom: 8px;
  background-color: #ffffff;
  border: 0px solid #dddddd;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}
.optionDatabaseTextBoxLabel {
  /* font-size: 14px; */
  /* color: #4d4d4d; */
  font-size: 12px;
  color: #9d9d9d;
  font-weight: 500;
  padding: 10px 14px 0px 14px;
}
.optionDatabaseTextInput {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0px;
  margin: 0px;
  border-width: 0px;
  min-width: 100%;
  resize: none;
  font-size: 14px;
  outline: 0px;
  padding: 4px 14px 0px 14px;
}
.optionDatabaseTextBoxFooter {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding: 10px 14px;
  border-top: 1px solid #eeeeee;
  flex-wrap: wrap;
}

.optionDatabaseTextBoxFooterItem {
  width: 30px;
  height: 30px;
  display: flex;
  color: #b400ff;
  font-weight: 700;
  margin-right: 14px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: #f6e2ff;
}

.spacingSubline {
  font-size: 12px;
  color: #9d9d9d;
  font-weight: 500;
  padding: 0px 14px;
}
.pt0 {
  padding-top: 0px;
}

.mtm15 {
  margin-top:-15px;
}

.mtm12 {
  margin-top:-12px;
}

.mtm6 {
  margin-top:-6px;
}

.mt10 {
  margin-top: 10px;
}

.mt20 {
  margin-top: 20px;
}

.mt50 {
  margin-top: 50px;
}

.mt30 {
  margin-top: 30px;
}

.pt50 {
  padding-top:50px;
}

.mb0 {
  margin-bottom: 0px;
}

.mb5 {
  margin-bottom: 5px;
}
.mb10 {
  margin-bottom: 10px;
}

.mb12 {
  margin-bottom: 12px;
}

.mb14 {
  margin-bottom: 14px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 30px;
}

.pb4 {
  padding-bottom: 4px;
}

.pb0 {
  padding-bottom: 0px;
}

.relative {
  position: relative;
}

.borderColorPicker {
  width: 39px;
  height: 39px;
  /* margin-top:9px; */
  border-radius: 8px;
  border: 4px solid #FFFFFF;
  outline: 1px solid #d5d5d5;
  /* box-shadow:0 1px 1px rgba(0, 0, 0, .06); */
}

.fInitial {
  flex: initial;
}

.f10 {
  font-size: 10px;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.containerDecor {
  min-width: 30px;
  min-height: 22px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #dbdbdb;
  background-color: #dbdbdb;
}
.containerDecorNew {
  min-width: 30px;
  min-height: 22px;
  border-radius: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border: 2px solid #dbdbdb;
  background-color: #dbdbdb;
}
.containerDecorPlusButton {
  width: 14px;
  height: 14px;
}

.optionItemLabels {
  font-size:12px;
  color:#939393;
}

.bgSetButton {
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0px solid #e2e2e2;
  background-color: #eaeaea;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #bdbdbd;
}

/* .containerDecorPlusButton {
    width: 12px;
    height: 12px;
    border-radius: 999px;
    border: 1px solid #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  } */

.containerDecorPlusButtonSecond {
  display: flex;
  flex-direction: row;
}
.containerDecorPlusButtonLine.one {
  width: 15px;
  height: 22px;
  min-width: 15px;
  min-height: 22px;
  background-color: #dbdbdb;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
}

.containerDecorPlusButtonLine.two {
  width: 15px;
  height: 22px;
  min-width: 15px;
  min-height: 22px;
  background-color: #dbdbdb;
  border-radius: 2px;
  border: 1px solid #f0f0f0;
}

.containerDecorTab {
  width: 15px;
  height: 22px;
  min-width: 15px;
  min-height: 22px;
  border-radius: 2px;
  background-color: #dbdbdb;
}

.containerDecorPlusButtonTabs {
  display: flex;
}

.containerDecorInner {
  margin: 2px;
  width: 8px;
  height: 3px;
  border-radius: 1px;
  background-color: #e6e6e6;
}
.containerDecorInnerTwo {
  margin: 2px;
  width: 8px;
  height: 3px;
  border-radius: 1px;
  background-color: #e6e6e6;
}

.containerScreenIco {
  min-width: 30px;
  min-height: 22px;
  border-radius: 2px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border: 1px solid #dbdbdb;
  background-color: #dbdbdb;
}

.containerScreenIcoInner {
  min-width: 14px;
  min-height: 14px;
  background-color: #e6e6e6;
}

.sliderDecorIco {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.sliderDecor {
  min-width: 15px;
  min-height: 22px;
  border: 2px solid #dbdbdb;
  margin: 0px -1px;
}
.sliderDecor.one {
  border-top-left-radius: 4px;
}
.sliderDecor.one {
  border-bottom-left-radius: 4px;
}

.sliderDecor.two {
  border-top-right-radius: 4px;
}
.sliderDecor.two {
  border-bottom-right-radius: 4px;
}
.tCenter {
  text-align: center;
}

.optionDivider {
  flex: 1;
  height: 1px;
  display: flex;
  margin: 2px 8px;
  background-color: #bdbdbd;
}

.bgImageIco {
  background-color: #ffffff;
}

.bgImageLine {
  width: 8px;
  height: 1px;
  border-radius: 1px;
  position: relative;
  background-color: #c4c4c4;
  position: absolute;
}
.bgImageLine.two {
  transform: rotate(90deg);
}
.bgBrand {
  background-color: #2d3035;
}

.headTabsDark .optionHeadTabItem.active {
  color:#212121;
  background-color: #FFFFFF;
}

.lightActive.advancedRadioItem {
  border-color: #ffffff;
  background-color: #ffffff;
  box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.06);
}

.lightActive.advancedRadioItem .advancedMultiLabel,
.lightActive.advancedRadioItem .advancedRadioLabel {
  color: #000000;
}

.optionConditionHeader {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  margin: 4px 10px;
  flex-direction: row;
}

.optionConditionHeaderItem {
  display: flex;
  margin: 4px 4px;
  padding: 5px 8px;
  border-radius: 6px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  background-color: #e4e4e4;
  border: 1px solid #e2e2e2;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
}

.optionConditionHeaderItem div {
  font-size: 8px;
  margin-right: 2px;
}

.optionConditionHeaderItem.active {
  background-color: #ffffff;
}

.optionConditionHeaderItemAdd {
  width: 30px;
  min-height: 44px;
  margin: 4px 4px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #e4e4e4;
  border: 1px solid #e2e2e2;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16); */
}

.optionConditionHeaderItemLine {
  width: 1px;
  height: 8px;
  border-radius: 2px;
  background-color: #2d3035;
  position: absolute;
}

.optionConditionHeaderItemLine.two {
  transform: rotate(90deg);
}

.removeCondition {
  margin: 8px;
  padding: 10px;
  display: flex;
  color: #ff0000;
  border-radius: 10px;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.mainCondition .optionCondition {
  margin-top: 8px;
}

.removeConditionIco {
  right: 6px;
  bottom: 9px;
  width: 14px;
  height: 14px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 999px;
  background-color: #44464a;
  justify-content: center;
}
.removeConditionIcoLine {
  width: 6px;
  height: 1px;
  position: absolute;
  /* transform: rotate(45deg); */
  background-color: #ffffff;
  margin: 0px !important;
}
.removeConditionIcoLine.two {
  /* transform: rotate(-45deg); */
}

.optionConditionHeaderItem.active .removeConditionIco {
  /* background-color: #ff0000; */
}

.paramListRow {
  gap: 10px;
  display:flex;
  padding: 0px;
  border-top:0px solid #eeeeee;
  position: absolute;
  right:10px;
  bottom:2px;
}
.paramListItem {
  display: flex;
  width: 30px;
  height: 30px;
  font-size: 12px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: #eeeeee;
}

.paramListItem.fButton {
  right: 32px
}

.paramListItem.gray {
  background-color: #f3f3f3;
  color: #000;
}

.filterRemoveRow {
  padding: 12px;
  display: none;
}

.filterRemoveItem {
  padding: 6px;
  text-align: center;
  border-radius: 6px;
  color: #ffffff;
  background-color: #2f2f2f;
}

.jointActionRow {
  display: flex;
  align-items: flex-start;
}

.jointActionClose {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: relative;
  justify-content: center;
  background-color: #2f2f2f;
  position: absolute;
  right: 8px;
  bottom: 8px;
  z-index: 999;
}

.jointActionCloseItem {
  width: 10px;
  height: 1px;
  position: absolute;
  border-radius: 0px;
  transform: rotate(45deg);
  background-color: #000000;
}

.jointActionCloseItem.two {
  transform: rotate(-45deg);
}

.jointActionClose:hover {
  width: 50px;
}

.jointActionClose:hover .jointActionCloseItem {
  display: none;
}

.jointActionClose:hover::before {
  font-size: 10px;
  color: #ff0000;
  font-weight: 700;
  content: "Delete";
}

.lightParamListRow {
  padding: 0px 12px 0px 12px;
  border-top: 0px solid #505050;
  border-bottom: 0px solid #505050;
}

.lightParamListItem {
  top: 12px;
  right: 12px;
  display: flex;
  width: 20px;
  height: 20px;
  font-size: 12px;
  color: #b400ff;
  font-weight: 800;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: absolute;
  background-color: #f6e2ff;
}

.optionItemRow.filterItemUnit {
  padding: 7px;
  margin: -7px -7px 0px -7px;
  background-color: #f0f0f0;
}

.optionItemRow.filterItemUnit .optionDatabaseBoxLabel {
  padding: 0px 0px 0px 0px;
}

.optionItemRow.filterItemUnit .optionDatabaseBox {
  box-shadow: none;
  background-color: transparent;
}

.optionItemRow.filterItemUnit .optionDatabaseFilterItem {
  padding: 7px 0px;
  border-top-width: 0px;
  background-color: transparent;
}

.optionItemRow.filterItemUnit .optionDatabaseFilterAddMore {
  color: #ffffff;
  border-radius: 10px;
  border-top-width: 0px;
  margin: 0px 0px 0px 0px;
  background-color: #2d3035;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.optionItemRow.filterItemUnit .optionDatabaseFilterMeta {
  margin: 7px 0px;
}

.modifiedBoxes .optionDatabaseBoxLabel {
  padding: 0px 0px 12px 0px;
}

.optionItem.linkingModule .optionItemInner {
  background-color: #12B505;
}

.optionItem.linkingModule .optionItemLabel {
  color: #ffffff;
}

.optionItem.linkingModule .optionItemDecor {
  background-color: #ffffff;
}

.optionItem.linkingModule .optionItemDecorLine {
  background-color: #31af26;
}

.linkingBoxWrapper {
  flex: 1 1;
  padding: 7px;
  display: flex;
  min-width:0;
  flex-direction: column;
}

.linkingBoxMain {
  position: relative;
  border-radius: 10px;
  background-color: #ffffff;
  border: 0px solid #dddddd;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.linkingBoxHead {
  padding: 6px;
  display: flex;
  border-bottom: 0px solid #E8E8E8;
}

.linkingBoxHead .optionItemBody {
  flex:1;
  display:flex;
  flex-direction:column;
}
.linkingBoxHead .linkingToBox {
  position:relative;
  background-color:#F5F5F5;
}

.linkingBoxFooter {
  padding: 6px;
  display: flex;
  background-color: #FFFFFF;
  border-top: 0px solid #E8E8E8;
  border-radius:0px 0px 10px 10px;
}

.linkingBoxFooter .optionItemBody {
  flex:1;
  display:flex;
  flex-direction:column;
}
.linkingBoxFooter .linkingToBox {
  background-color:#F5F5F5;
}

.linkingBoxBody {
    min-width:0;
    display:flex;
    background-color:#fafafa;
    border-top:1px solid #e8e8e8;
    border-bottom:1px solid #e8e8e8;
}

.linkingBoxDecor {
  width:1px;
  margin:0px 15px 0px 30px;
  background-color:#E2E2E2;
}

.linkingBoxItem {
  flex:1;
  min-width:0;
  display:flex;
  padding: 7px;
}

.optionItem {
  /* display: none; */
}
.linkingModule {
  display: block;
}

.selfSeperator {
  width: 4px;
  height: 16px;
  margin: auto;
  background-color: #f0f0f0;
}

.linkingOpen .optionDatabaseRadio {
  margin-left: 0px;
}

.removeX {
  top: 12px;
  right: 16px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 999px;
  background-color: #f5f5f5;
  box-shadow:inset 0px 2px 6px rgba(0,0,0,0.08);
}

.removeXLine {
  width: 1px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  background-color: #8d8d8d;
}
.removeXLine.one {
  transform: rotate(-45deg);
}

.linkingModule .optionConditionTableFilterMetaQuery {
  background-color: #FFFFFF;
}
.linkingModule .optionConditionTableSelectorFilterItemContext {
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.08);
}

.linkingModule .optionConditionTableFilterMetaParams {
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.linkingModule .optionConditionTableFilterMetaQueryInput {
  color: #2d3035;
}

.parameterGroup {
  margin: 7px;
  margin-top: 10px;
  border-radius: 10px;
  background-color: #dadada;
}

.parameterGroup .optionConditionTableSelectorFilterItem.one {
  padding-bottom: 0px;
}

.parameterLabel {
  color: #999999;
  padding: 7px 10px 0px 10px;
}

.parameterGroup .removeX {
  top: 10px;
  right: 8px;
  width: 14px;
  height: 14px;
  background-color: #dadada;
}

.parameterGroup .removeXLine {
  height: 12px;
}

.linkParamHead {
  padding-top: 8px;
  padding-right: 7px;
  padding-bottom: 4px;
}

.addLinkParameter {
  margin: 7px;
}

.addLinkParameter {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 7px;
  margin-top: 10px;
  color: #ffffff;
  border-radius: 10px;
  background-color: #2d3035;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

iframe {
  display:none;
}

.calculationOutside {
  top: 0px;
  left: 0px;
  right:0px;
  bottom:0px;
  margin: auto;
  z-index:9;
  position: fixed;
  background-color:rgba(0,0,0,0.60);
}

.calculationWindow {
  top: 0px;
  right: 0px;
  width: 350px;
  margin: auto;
  position: absolute;
  border-radius: 10px;
  background-color: #f5f5f5;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  max-height:100vh;
  overflow-y:auto;
}

.calculationWindow .calcBody {
  overflow-y: auto;
  height: 425px;
}

.calculationHead {
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e9e9e9;
  background-color: #ffffff;
  border-radius: 10px 10px 0px 0px;
}

.calculationeadLabel {
  color: #2d3035;
  font-weight: 800;
}

.calculationClose {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: #f5f5f5;
  position: relative;
  transform: scale(1.25);
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.calculationCloseLine {
  width: 1px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  background-color: #bcbcbc;
}

.calculationCloseLine.two {
  transform: rotate(-45deg);
}

.calculationHeadBack {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: #f5f5f5;
  position: relative;
  transform: scale(1.25);
  box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.calculationHeadBackLine {
  width: 1px;
  height: 6px;
  transform: rotate(45deg);
  background-color: #bcbcbc;
  margin-top: -4px;
  margin-left: -6px;
  position: absolute;
}
.calculationHeadBackLine.one {
  margin-top: 4px;
  transform: rotate(-45deg);
}

.calculationHeadBackLineHorizontal {
  height: 1px;
  width: 10px;
  position: absolute;
  background-color: #bcbcbc;
  margin-top: 0px;
}

.calculationHeadDecor {
  width: 20px;
  height: 20px;
  border-radius: 999px;
  background-color: #f5f5f5;
}

.calculationHeadSubline {
  padding: 12px 16px;
  border-bottom: 1px solid #e9e9e9;
  background-color: #ffffff;
  text-align: center;
}

.calculationHeadSubline span {
  color: #b600ff;
  font-weight: 700;
}

.calculationBody {
  display: flex;
  flex-direction: column;
  max-height: 450px;
  overflow-y: auto;
}

.calculationBodyItem {
  display: flex;
  flex-direction: row;
  padding: 8px;
}

.calculationBodySide {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calculationBodyMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.calculationOrder {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #2d3035;
  font-weight: 800;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.calculationLine {
  flex: 1;
  display: flex;
  width: 1px;
  margin-top: 14px;
  background-color: #e2e2e2;
}

.calculationTypeBox {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
  margin-bottom: 14px;
}

.calculationTypeBoxRadio {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: #eaeaea;
  margin: 0px 14px;
}

.calculationTypeBoxRadio div {
  width: 12px;
  height: 12px;
  border-radius: 999px;
  background-color: #ffffff;
}

.calculationTypeBoxMain {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 14px;
  padding: 2px 0px;
}

.calculationTypeBoxSeperator {
  height: 1px;
  background-color: #f4f4f4;
}

.calculationTypeBox .optionDatabaseSelectChevron {
  margin-right: 0px;
}

.calculationTypeBoxSelect {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #4d4d4d;
  font-weight: 800;
  padding: 6px 0px;
}

.calculationTypeBoxSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0px;
}

.calculationTypeBoxSelectorLabel {
  flex: 1;
  display: flex;
  color: #939393;
}

.calcTeal .calculationTypeBoxLabel {
  color: #00ceff;
}

.calcGreen .calculationTypeBoxLabel {
  color: #2db900;
}

.calcPurple .calculationTypeBoxLabel {
  color: #b600ff;
}

.calcOrange .calculationTypeBoxLabel {
  color: #ef8841;
}

.calcBlack .calculationTypeBoxLabel {
  color: #2d3035;
}

/* .calculationList .calcTeal {
  border-bottom: 2px solid #00ceff;
}

.calculationList .calcGreen {
  border-bottom: 2px solid #2db900;
}

.calculationList .calcPurple {
  border-bottom: 2px solid #b600ff;
}

.calculationList .calcOrange {
  border-bottom: 2px solid #ef8841;
}

.calculationList .calcBlack {
  border-bottom: 2px solid #2d3035;
} */

.calculationList .calculationTypeBox:nth-last-child(1) {
  margin-bottom: 0px;
}

/* .calculationBody .calculationBodyItem:nth-child(1) {
  padding-bottom: 0px;
} */

.calculationBody .calculationBodyItem:not(:first-child) {
  padding-top: 0px;
}

.calculationAdd {
  margin: 0px 16px 16px 16px;
  cursor: pointer;
}

.calculationAdd.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.calculationAddItem {
  flex: 1;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 10px;
  background-color: #2d3035;
  /* background-color: #b500ff; */
}

.calculationBody .calculationBodyItem.calculationOperator {
  padding-top: 8px;
  padding-bottom: 8px !important;
  border-top: 1px solid #e9e9e9;
}

.calculationOperation {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.calculationOperatorItem {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  height: 30px;
  border-radius: 10px;
  background-color: #b500ff;
  border: 0px solid #e9e9e9;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.calculatorOperatorClose {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
  margin-left: 14px;
  border-radius: 10px;
  background-color: #ffffff;
  border: 0px solid #e9e9e9;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.calculatorOperatorCloseLine {
  width: 1.5px;
  height: 10px;
  position: absolute;
  transform: rotate(45deg);
  background-color: #ff0000;
}

.calculatorOperatorCloseLine.two {
  transform: rotate(-45deg);
}

.calculationOperatorItemLabel {
  color: #ffffff;
}

.calculationOperatorItem .optionDatabaseSelectChevron {
  margin-right: 0px;
  filter: brightness(100);
}

.calculationConnector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -14px;
}

.calculationConnectorItem {
  width: 4px;
  height: 16px;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08);
}

.calculationMainItem.calculationConnected .calculationTypeBox {
  margin-bottom: 0px;
}

.calculationBody .databasedataselector {
  border-radius: 10px;
  margin-bottom: 0px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.calculationBody .optionConditionTableSelectorHead {
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #e9e9e9;
}

.calculationBody .optionConditionTableSelectorHeadSubline {
  color: #00ceff;
}

.calculationBody .optionConditionTableSelector {
  background-color: #f8f8f8;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.calculationBody .optionConditionTableSelectorHeadSublineFilter {
  color: #939393;
}

.calculationBody .optionDatabaseValueSelector {
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.calculationBody .optionConditionTableSelectorHeadSelectLabel {
  color: #939393;
}

.calculationBody .optionConditionTableSelectorFilterItemContext {
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
}

.calculationBody .optionConditionTableFilterMetaParams {
  border-color: #e9e9e9;
}

.calculationBody .optionConditionTableFilterMetaQuery {
  background-color: #f7f7f7;
}

.calculationBody .optionConditionTableParam {
  color: #2d3035;
}

.calculationBody .optionConditionTableSelectorAddFilterLabel {
  color: #b600ff;
  background-color: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
}

.calculationBody .optionDatabaseValueSelectorLabel {
  color: #939393;
}

.calculationBody .optionDatabaseValueSelectorSelect {
  border: 1px solid #e9e9e9;
  background-color: #f8f8f8;
}

.calculationBody .paramListItem {
  background-color: #f6e2ff;
}

.calculationBody .jointActionClose {
  background-color: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
}

.calculationBody .jointActionCloseItem {
  height: 1.15px;
  border-radius: 0px;
  background-color: #ff0000;
}

.calculationBody
  .databasedataselector
  .optionConditionTableSelector
  .optionConditionTableSelectorHead:nth-child(2) {
  background-color: #ffffff;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.calculationBody
  .databasedataselector
  .optionConditionTableSelector
  .optionConditionTableSelectorHead:nth-child(2)
  .optionConditionTableSelectorHeadSelect {
  margin: 4px 14px 4px 14px;
  padding: 8px 12px;
  border-radius: 8px;
}

.calculationBody .optionConditionTableFilterMetaQueryInput {
  color: #2d3035;
}

.calculationBody .optionConditionTableSelectorAddFilter {
  padding: 7px 7px 7px 7px;
}

.calculationBody .addFilterGroup {
  display: none;
}

.calculationBody .optionConditionTableSelectorHeadSelect {
  padding: 8px 12px;
  border-radius: 8px;
  margin: 4px 14px 4px 14px;
  background-color: #f5f5f5;
}


.checkBoxify {
  border-radius: 4px;
}
.checkBoxify .optionDatabaseRadioDecor {
  width: 14px;
  height: 14px;
  border-radius: 2px;
}

.checkBoxify.active .optionDatabaseRadioDecor {
  background-color: #212121;
}

.checkBoxify.active .optionDatabaseRadioDecor::before {
  content: "";
  width: 1px;
  height: 6px;
  margin-left: 2px;
  position: absolute;
  background-color: #ffffff;
  transform: rotate(45deg);
}
.checkBoxify.active .optionDatabaseRadioDecor::after {
  content: "";
  width: 1px;
  height: 3px;
  margin-left: -4px;
  margin-top: 2px;
  position: absolute;
  background-color: #ffffff;
  transform: rotate(-45deg);
}



.stillRadio.checkBoxify .optionDatabaseRadioDecor {
  width: 12px;
  height: 12px;
  border-radius: 999px;
}

.stillRadio.checkBoxify {
  border-radius: 999px;
}

.stillRadio.checkBoxify .optionDatabaseRadioDecor::before {
  display: none;
}

.stillRadio.checkBoxify .optionDatabaseRadioDecor::after {
  display: none;
}
.stillRadio.active .optionDatabaseRadioDecor {
  background-color: #b400ff;
}

.bionicTabs {
  flex: 1;
  height: 42px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 3px;
  background-color: #f7f7f7;
  border: 1px solid #d5d5d5;
}

.bionicTabsItem {
  flex: 1;
  margin: 3px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.bionicTabsItem.active {
  background-color: #ffffff;
  border: 1px solid #eeeeee;
}

.galleryDecor {
  justify-content: space-between;
}

.galleryDecor .containerDecorPlusButton {
  width: 3px;
  height: 3px;
  margin: 2px;
  border-radius: 999px;
  background-color: #f0f0f0;
}

.hide {
  display: none;
}

.uploadPhotoContainer {
  margin: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.uploadPhotoItem {
  width: calc(33% - 14px);
  height: 65px;
  margin: 7px;
  display: flex;
  position: relative;
}

.uploadPhoto {
  flex: 1;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  object-fit: cover;
}

.deletePhoto {
  top: 8px;
  right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  border-radius: 4px;
  background-color: #2d3035;
}

.deletePhotoLine {
  width: 1px;
  height: 10px;
  border-radius: 1px;
  position: absolute;
  transform: rotate(-45deg);
  background-color: #ffffff;
}

.deletePhotoLine.two {
  transform: rotate(45deg);
}

.optionDatabaseTextBoxFooterUpload {
  width: 30px;
  height: 30px;
  display: flex;
  font-weight: 700;
  margin-right: 14px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: #2db900;
  position: relative;
}

.optionDatabaseTextBoxFooterUploadLine {
  width: 2px;
  height: 10px;
  border-radius: 0px;
  position: absolute;
  background-color: #ffffff;
}

.optionDatabaseTextBoxFooterUploadLine.two {
  transform: rotate(90deg);
}

/* .optionDatabaseTextBox {
  border: 3px solid #f7f7f7;
} */

.radioOptionify .active .optionDatabaseTextBox {
  border: 3px solid #b400ff;
}

.triangle {
  position: relative;
  background-color: #f0f0f0;
  text-align: left;
}
.triangle:before,
.triangle:after {
  content: "";
  position: absolute;
  background-color: inherit;
}
.triangle,
.triangle:before,
.triangle:after {
  width: 4px;
  height: 4px;
  border-top-right-radius: 30%;
}

.triangle {
  transform: rotate(30deg) skewX(-30deg) scale(1, 0.866);
}
.triangle:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
    translate(0, -50%);
}
.triangle:after {
  transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
}

.audioDecor {
  width: 20px;
  height: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.audioDecorLine {
  width: 2px;
  height: 6px;
  margin: 0px 1px;
  border-radius: 999px;
  background-color: #f0f0f0;
}

.audioDecorLine.one {
  height: 6px;
}
.audioDecorLine.two {
  height: 8px;
}
.audioDecorLine.three {
  height: 10px;
}
.audioDecorLine.four {
  height: 8px;
}
.audioDecorLine.five {
  height: 4px;
}

.chartDecor {
  width: 20px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chartDecorLine {
  width: 3px;
  height: 3px;
  border-radius: 999px;
  border: 1px solid #f0f0f0;
}

.chartDecorLine.one {
  margin-bottom: -3px;
}
.chartDecorLine.two {
  margin-top: -6px;
}
.chartDecorLine.three {
  margin-bottom: -3px;
}
.chartDecorLine.four {
  margin-top: -6px;
}

.displayNone {
  display: none !important;
}
.mt0 {
  margin-top: 0px;
}

.optionMapDatabaseWrapper {
  padding-bottom: 1px;
}

.optionMapDatabase {
  border-radius: 10px;
  margin: 0px 14px 14px 14px;
  background-color: #d9d9d9;
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.15);
}
.optionMapDatabase .optionDatabaseSelector {
  box-shadow: none;
  padding-bottom: 0px;
  background-color: transparent;
}
.optionMapDatabase .optionItemRow.filterItemUnit {
  background-color: transparent;
}

.optionMapDatabase .optionItemRow.filterItemUnit .optionDatabaseFilterAddMore {
  margin-bottom: -6px;
}

.optionMapDatabase .optionDatabaseValueSelectorSelect {
  margin: 0px;
  margin-top: 8px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 3, 6, 0.16);
}

.addressSelector {
  margin: 22px -14px -20px -14px;
  padding: 10px 14px 14px 14px;
  border-top: 1px solid #bfbfbf;
}

.mapIconSection .linkingOpen.flexRow.bgWhite.shadow.flex1.br10 {
  padding: 4px;
  margin: 8px 0px 0px 0px;
}

.linkingOpen.flexColumn {
  width:100%;
}

.optionDatabaseBoxLabelMultiUnit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.optionItemIconDimension {
  margin: 0px -7px;
}
.optionItemIconDimension .optionInputIconBox {
  border-width: 0px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 3, 6, 0.16);
}

.optionMapSingular .optionMapAdjust {
  margin-top: -14px;
  border-top-width: 0px;
}

.optionMapCombo {
  display: flex;
  flex-direction: row;
}

.optionMapNumberWrapper {
  min-width: 30px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d9d9d9;
  border-radius: 999px;
  margin-left: 14px;
  margin-right: -6px;
}

.optionMapNumber {
  width: 20px;
  height: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 999px;
  text-align: center;
  font-size: 12px;
}

.mapLinkingCombo {
  display: flex;
  flex-direction: column;
}

.mapLinkingWrapper {
  margin: 14px 14px 14px 14px;
}

.optionAddLinkingButton {
  color: #ffffff;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border-top-width: 0px;
  margin: 0px 0px 0px 0px;
  background-color: #31af26;
  background-color: #2d3035;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.optionMapLeft {
  display: flex;
  flex-direction: column;
}

.optionMapLeftDecor {
  flex: 1;
  width: 1px;
  display: flex;
  margin: 8px 0px 14px 28px;
  background-color: #d8d8d8;
}

.custom-select {
  position:relative;
}

.custom-select .options-list {
  z-index: 1;
  width: max-content;
}

.optionHeadRight {
  display:flex;
  flex-direction: row;
  align-items: center;
}

.hover-selector {
  width: 28px;
  height: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0px solid #e2e2e2;
  background-color: #eaeaea;
  border-radius: 4px;
  margin-right: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #bdbdbd;
}
.hover-selector.active {
  color:#000000;
}











.calcHead {
  display:flex;
  padding:14px 20px;
  align-items: center;
  background-color:#FFFFFF;
  border-radius:10px 10px 0px 0px;
  border-bottom:1px solid #E8E8E8;
}
.calcHeadMeta {
  flex:1;
  display:flex;
  flex-direction:column;
}

.calcHeadAction {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #f5f5f5;
  position: relative;
  box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.16);
}
.calcHeadLabel {
  font-size:14px;
  font-weight:500;
  color:#434343;
  text-align:center;
}

.calcHeadSublineLabel {
  font-size:14px;
  font-weight:300;
  color:#434343;
  text-align:center;
}
.calcHeadSublineLabel span {
  color:#B426FB;
}



.calcItemHeadAction {
  display:flex;
  background-color:#FFFFFF;
  padding:17px 20px 0px 20px;
  border-top:1px solid #E8E8E8;
}
.calcItemRemove {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 12px;
  margin-right: 20px;
  background-color: #F5F5F5;
  /* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08); */
}
.calcItemRemove div {
  background-color:#A80000;
}
.calcDropdown {
  flex:1;
  display:flex;
  align-items: center;
  background-color:#F5F5F5;
  border-radius:8px;
}
.calcDropdownLabel {
  flex:1;
  display:flex;
  font-size:13px;
  font-weight:300;
  color:#434343;
}

.calcItemHead {
  gap: 20px;
  display:flex;
  background-color:#FFFFFF;
  border-bottom:1px solid #E8E8E8;
  padding:17px 20px;
}
.calcItemHeadOrder {
  min-width:36px;
  height:36px;
  color:#434343;
  font-weight:bold;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:12px;
  background-color:#F5F5F5;
}
.calcItemHeadTab {
  flex:1;
  padding:3px;
  display:flex;
  font-size:12px;
  font-weight:300;
  border-radius:12px;
  background-color:#F5F5F5;
}
.calcItemHeadTabItem {
  flex:1;
  display:flex;
  align-items: center;
  justify-content: center;
  padding:0px 15px;
  border-radius:6px;
}
.conditionDropdown .calcItemHeadTabItem.active {
  /* color:#10b507; */
  border-radius:8px;
  background-color:#FFFFFF;
  /* outline: 1px solid #e8e8e8; */
  /* box-shadow: 0px 0px 3px rgba(0,0,0,0.20); */
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}
.tabInformation.active {
  color:#ED8849;
}
.tabDatabase.active {
  color:#00CEFF;
}
.tabValue.active {
  color:#12B505;
}









.calcItemBody {
  display:flex;
}
.calcItemDecor {
  min-width:1px;
  margin:0px 38px;
  background-color:#E2E2E2;
}
.calcItemBodyMain {
  flex:1;
  display:flex;
  flex-direction: column;
  padding:15px 15px 15px 0px;
  min-width:0;
}
.calcItemBodyMainHeader {
  display:flex;
  flex-direction:row;
  align-items: center;
  margin-top:14px;
  justify-content: space-between;
}
.calcItemBodyMainHeaderSecondary {
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  margin-top:20px;
}
.calcItemBodyMainHeaderLabel {
  font-size:10px;
  color:#000000;
  font-weight:500;
}
.calcItemBodyMainHeaderAction {
  font-size:10px;
  color:#A7A7A7;
  font-weight:600;
}

.calcItemUnit {
  display:flex;
  flex-direction:column;
  margin-top:15px;
  border-radius:10px;
  background-color:#FFFFFF;
  box-shadow:0px 2px 2px rgba(0,0,0,0.08);
}

.calcItemUnitBox {
  flex:1;
  display:flex;
  align-items:center;
}

.calcItemUnitDecor {
  min-width:16px;
  height:16px;
  margin:0px 12px 0px 12px;
  border-radius:999px;
  border:3px solid #EAEAEA;
  background-color:#171717;
}
.calcItemUnitDecorUnselected {
  min-width:16px;
  height:16px;
  margin:0px 12px 0px 12px;
  border-radius:999px;
  border:3px solid #EAEAEA;
  background-color:#FFFFFF;
}
.calcItemUnitMain {
  flex:1;
  display:flex;
  flex-direction: column;
  padding:12px 12px 12px 12px;
}
.calcItemUnitMainCombo {
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
}
.calcItemUnitMainLabel {
  font-size:12px;
  font-weight:300;
  color:#171717;
  margin-bottom:6px;
}
.calcItemUnitSublineLabel {
  font-size: 12px;
  font-weight: 300;
  color: #171717;
  margin-bottom: 6px;
}
.calcItemUnitSublineLabel span {
  color: #888888;
}
.calcItemUnitMainUnselectedLabel {
  font-size:12px;
  font-weight:400;
  color:#171717;
}
.calcItemUnitMainDropdown {
  flex:1;
  display:flex;
  flex-direction:row;
  align-items: center;
  border-radius:8px;
  min-height:36px;
  background-color:#F5F5F5;
}
.calcItemUnitMainDropdown.adjust {
  min-height:32px;
}
.calcItemUnitMainDropdownLabel {
  flex:1;
  width:100%;
  display:flex;
  font-size:12px;
  font-weight:300;
  color:#888888;
  border-width:0px;
  padding:0px;
  padding-left:10px;
  align-items: center;
  background-color:transparent;
  max-width:100%;
}
.calcItemUnitMainDropdownIco {
  width:12px;
  height:12px;
  margin-left:12px;
  object-fit: contain;
}

.calcItemUnitMainDropdownFooter {
  display:flex;
  flex-direction:row;
  align-items: center;
  padding:0px 10px 0px 0px;
}
.calcItemUnitMainDropdownFooterLabel {
  font-size:12px;
  font-weight:300;
  color:#888888;
}
.calcItemUnitMainDropdownFooterArrow {
  width:14px;
  height:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
  margin-left:5px;
}

.calcItemUnitMainDropdownFooterArrow div {
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  margin:auto;
  position:absolute;
  background-color:#434343;
}
.calcItemBody .calcItemUnitMainDropdownFooterArrow div {
  background-color:#939393;
}

.calcItemUnitMainDropdownFooterArrow div.one {
  width:6px;
  height:1px;
  margin-right:2px;
  transform:rotate(-45deg);
}
.calcItemUnitMainDropdownFooterArrow div.two {
  width:6px;
  height:1px;
  margin-left:2px;
  transform:rotate(45deg);
}
.calcItemUnitFooter {
  gap:12px;
  padding:12px;
  display:flex;
  flex-direction:column;
}
.calcItemUnitFooter .calcItemUnitMain {
  padding:0px;
}




.calcItemDatabaseFilter {
  flex:1;
  padding:6px;
  display:flex;
  padding-left:33px;
  position: relative;
  flex-direction: column;
  background-color:#fafafa;
  border-top:1px solid #E8E8E8;
  border-bottom:1px solid #E8E8E8;
}
.calcItemDatabaseFilterLabel {
  font-size:12px;
  /* font-weight:500; */
  color:#434343;
  padding:6px;
}
.calcSpace {
  width:12px;
  height:12px;
}
.calcItemDatabaseFilterBox {
  padding:6px;
}
.calcItemDatabaseFilterBoxInner {
  gap:6px;
  padding:6px;
  display:flex;
  flex-direction: column;
  border-radius:8px;
  border:1px solid #e8e8e8;
  background-color:#FFFFFF;
  /* box-shadow:0px 2px 1px rgba(0,0,0,0.08); */
}
.calcItemDatabaseFilterBoxInnerRow {
  flex:1;
  gap:6px;
  display:flex;
  flex-direction:column;
}
.calcItemDatabaseFilterBoxInnerRow .calcItemUnitMainDropdown {
  min-height:32px;
}
.calcItemDatabaseFilterBoxInnerRow .calcItemUnitMainDropdown .calcItemUnitMainDropdownLabel {
  color:#434343;
}

.calcItemDatabaseFilterLabelRow {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.calcItemDatabaseFilterOptions {
  font-size: 12px;
  font-weight: 500;
  color: #434343;
  display:flex;
}
.filterOptionItem {
  opacity:0.2;
  padding: 6px;
}
.filterOptionItem.active {
  opacity:1;
}

.addFilter {
  width:20px;
  height:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
  border-radius:6px;
  margin-right:6px;
  border:1px solid #e8e8e8;
  background-color:#FFFFFF;
  /* box-shadow:0px 2px 1px rgba(0,0,0,0.08); */
}
.addFilter div {
  width:8px;
  height:1px;
  background-color:#434343;
  position:absolute;
}
.addFilter .two {
  transform:rotate(90deg);
}


.filterFooter {
  display:flex;
  align-items: center;
}

.removeFilter {
  width:20px;
  height:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
  border-radius:6px;
  margin-right:6px;
  background-color:#FFFFFF;
  border:1px solid #e8e8e8;
  /* box-shadow:0px 2px 1px rgba(0,0,0,0.08); */
}
.removeFilter div {
  width:8px;
  height:1px;
  background-color:#A80000;
  position:absolute;
  transform:rotate(45deg);
}
.removeFilter .two {
  transform:rotate(-45deg);
}

.filterSet {
  /* margin:6px;
  padding:6px;
  border-radius:8px;
  background-color:#e3e3e3;
  border:0px solid #ffffff;
  box-shadow: inset 0px 0px 2px rgba(0,0,0,0.08); */
}

.calcDatabaseFilterMultiBox {
  flex:1;
  /* display:flex; */
  font-size:12px;
  color:#AFAFAF;
  min-height:32px;
  border-radius:8px;
  position:relative;
  background-color:#F5F5F5;
  padding:7px 20px 7px 10px;
}

.parameterButton {
  width:20px;
  height:20px;
  color:#171717;
  font-weight:600;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:4px;
  background-color:#FFFFFF;
  position:absolute;
  top:6px;
  right:6px;
}

.dbfRemove {
  width:20px;
  height:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 4px;
  background-color:#FFFFFF;
  position:absolute;
  bottom:7px;
  right:7px;
  display:none
  /* box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.08); */
}
.dbfRemove div {
  background-color:#A80000;
}

.pad6 {
  padding:6px;
}

.pad12 {
  padding:12px;
}

.addFilterButton {
  font-size:12px;
  font-weight:300;
  color:#171717;
  min-height:32px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:8px;
  background-color:#FFFFFF;
  box-shadow:0px 2px 1px rgba(0,0,0,0.08);
}
.addFilterButton.dark {
  color:#FFFFFF;
  background-color:#434343;
}

.spaceTopify {
  margin-top:6px;
}

.calcItemDatabaseFilterTab {
  flex:1;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding:6px;
}

.calcItemDatabaseFilterTabItem {
  display:flex;
  align-items: center;
  border-radius:8px;
  opacity:0.15;
  position:relative;
}
.calcItemDatabaseFilterTabItem.active {
  opacity:1;
}

.calcItemDatabaseFilterTabLabel {
  font-size: 12px;
  font-weight: 300;
  color: #434343;
}
.active .calcItemDatabaseFilterTabLabel {
  font-weight:500;
}

.calcItemDatabaseFilterCount {
  height:14px;
  min-width:14px;
  font-size:10px;
  color:#FFFFFF;
  border-radius:999px;
  background-color:#000000;
  display:flex;
  align-items: center;
  justify-content: center;
  margin-left:5px;
}
.active .calcItemDatabaseFilterCount {
  background-color:#171717;
}

.column {
  flex-direction:column;
}


.addOperation {
  flex: 1;
  height:44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F5F5F5;
  border-radius: 12px;
}

.addOperationLabel {
    display: flex;
    font-size: 12px;
    font-weight: 300;
    color: #434343;
}





.databaseOperationModule {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: #FFFFFF;
  /* border:1px solid #E2E2E2; */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.domHeader {
  gap:6px;
  padding:12px;
  display: flex;
  flex-direction: column;
}

.domLabel {
  flex: 1 1;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #171717;
}

.domLabel.spaceTopify {
  margin-top:12px;
}

.domBody {
  flex: 1;
  padding: 6px;
  display: flex;
  flex-direction: column;
  background-color: #ededed;
  border-radius: 0px 0px 10px 10px;
}

.domBodys {
  flex: 1;
  padding: 6px;
  display: flex;
  flex-direction: column;
  background-color: #ededed;
  border-radius: 0px 0px 10px 10px;
}

.domAdjusted {
  padding:0px;
}

.domEditDatabaseHead {
  flex: 1;
  padding: 0px 6px 6px 6px;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-top: 0px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  margin-top: -6px;
}
.domEditDatabaseHead .addFilterButton {
  box-shadow:none;
  background-color:#f5f5f5;
}
.domEditDatabaseHead .calcItemDatabaseFilterLabel {
  font-size: 12px;
  font-weight: 300;
  color: #888888;
}
.domEditDatabaseHead .calcItemUnitMainDropdown {
  
}
.domEditDatabaseHead .calcDatabaseFilterMultiBox {
  padding:7px 20px 10px 10px;
  background-color:#FFFFFF;
}
.domEditDatabaseHead .parameterButton , .domEditDatabaseHead .dbfRemove {
  background-color:#F5F5F5;
}
.domEditDatabaseHead .calcItemDatabaseFilterBoxInnerRow {
  gap:3px;
}

.databaseOperationModule .calcItemUnitMainDropdownLabel {
  font-size:12px;
  color:#434343;
}

.calcItemDBLabel {
  padding:0px 6px;
  font-size: 12px;
  font-weight: 400;
  color: #888888;
}


.add .calcItemUnitMainDropdownLabel {
  /* color:#12B505; */
}
/* .add .calcItemUnitMainDropdownFooterArrow div {
  background-color:#12B505;
} */

.edit .calcItemUnitMainDropdownLabel {
  color:#ED8849;
}
/* .edit .calcItemUnitMainDropdownFooterArrow div {
  background-color:#ED8849;
} */

.delete .calcItemUnitMainDropdownLabel {
  /* color:#A80000; */
}
/* .delete .calcItemUnitMainDropdownFooterArrow div {
  background-color:#A80000;
} */


.linkingBoxMain .cGrey.f14.mb10 {
  font-size: 12px;
  color: #565656;
  font-weight: 600;
}

.linkingBoxMain .cDark.flex1.cPurple {
  color:#434343;
}

.linkLabel {
  font-size: 12px;
  color: #434343;
  font-weight: 500;
  margin-bottom:10px;
}

.databaseItemHead {
  display: flex;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;
  padding: 17px 12px;
}
.databaseItemHeadOrder {
  min-width: 36px;
  height: 36px;
  color: #434343;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 12px;
  background-color: #F5F5F5;
  /* background-color: #212121; */
  /* color:#FFFFFF; */
}

.databaseItemHeadTab {
  flex: 1 1;
  padding: 3px;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  border-radius: 12px;
  background-color: #F5F5F5;
}
.databaseItemHeadTabItem {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
}

.databaseItemHeadTabItem.active {
  border-radius: 8px;
  background-color: #FFFFFF;
}

.calcDropdown.add .calcDropdownLabel {
  /* color:#12B505; */
}

.calcDropdown.edit .calcDropdownLabel {
  /* color:#ED8849; */
}

.calcDropdown.delete .calcDropdownLabel {
  /* color:#A80000; */
}

.databaseItemActionBar {
  display:flex;
  justify-content: space-between;
}

.databaseItemActionLabel {
  font-size: 10px;
  color: #171717;
  font-weight: 600;
  margin-bottom: 10px;
}

.databaseItemActionRemove {
  font-size: 10px;
  color: #A7A7A7;
  font-weight: 600;
  margin-bottom: 10px;
}

.linkLabel.success {
  color:#12B505;
}
.linkLabel.fail {
  color:#A80000;
}
.linkLabel.completion {

}

.linkDropdown {
  overflow:hidden;
  border-radius:12px;
  background-color:#FFFFFF;
  margin:12px -13px -14px -13px;
  /* border: 1px solid #e2e2e2; */
  /* box-shadow:0px 1px 2px 1px rgb(0 0 0 / 25%); */
  border: 1px solid #dbdce3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);
}
.linkDropdownHead {
  padding:12px;
  border-bottom:1px solid #E8E8E8;
}
.linkDropdownHead .calcItemHeadTab {
  min-height:44px;
}

.calcItemHeadTab {
  gap:0px;
  min-height:36px;
}
.linkDropdownBody {
  gap:12px;
  padding:12px;
  display:flex;
  flex-direction:column;
  background-color:#f5f5f5;
}
.linkDropdownLabel {
  font-size: 10px;
  color: #A7A7A7;
  font-weight: 600;
}
.linkDropdownBody .calcItemUnit {
  margin-top:0px;
}

.linkDropdownRow {
  gap:12px;
  display:flex;
}
.linkDropdownRow .calcItemUnit {
  flex:1;
}

.linkDropdown .calcItemUnitMainUnselectedLabel {
  color:#888888;
}
.linkDropdown .calcItemUnit.active .calcItemUnitMainUnselectedLabel {
  color:#171717;
}
.linkDropdown .calcItemUnit.active .calcItemUnitDecorUnselected {
  background-color: #171717;
}

.developmentHide .optionItemRow {
  /* display:none; */
}

.linkingOptionsBox {
  display:flex;
  flex-direction:column;
  margin-left:-6px;
  margin-right:-6px;
  margin-bottom:-6px;
}

.linkingOptionsBox .optionDatabaseRadio {
  margin-left:0px;
}

.linkingOptionsBox .cGrey {
  font-size:12px;
}

.linkingOptionsBox .checkBoxify.active .optionDatabaseRadioDecor {
  background-color:#10b507;
}

.loopHead {
  display:flex;
  background-color:#fafafa;
  border-top:1px solid #e8e8e8;
  border-bottom: 1px solid #E8E8E8;
}

.loopDecor {
  width: 1px;
  margin: 0px 15px 0px 30px;
  background-color: #E2E2E2;
}

.loopContext {
  flex:1;
  display:flex;
  flex-direction:column;
  padding:7px;
}

.loopDatabase {
  box-shadow:none;
  background-color:#FFFFFF;
}
.loopDatabase .domHeader .calcItemUnitMainDropdown {
  background-color:#f5f5f5;
}

.loopLinking {
  padding: 6px;
  display: flex;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E8E8E8;
}
.loopLinking .linkingToBox {
  background-color: #F5F5F5;
}

.loopContext .linkingToBox {
  position:relative;
  background-color:#F5F5F5;
}

.loopContext .custom-select.linkingToBox.flexColumn.flex1.br10.pad6 {
  padding:0px 0px;
  background-color:#FFFFFF;
  border: 1px solid #e2e2e2; 
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, .06); */
}

.repeatingDropdownSelect {
  border-radius:8px;
}

.repeatingDropdownSelect .domHeader {
  padding:0px;
}

.repeatingDropdownSelect .custom-select-head.calcItemUnitMainDropdown {
  min-height:34px;
  padding:8px 12px;
  border-radius:10px;
  background-color:#FFFFFF;
}

.repeatingDropdownSelect .optionDatabaseSelectChevron {
  margin-right:0px;
}

.repeatingDropdownSelect .selected-option.calcItemUnitMainDropdownLabel {
  font-weight:400;
  padding-left:0px;
}

.custom-select-head.pad6.flexRow.aCenter {
  padding:8px 12px;
}

.loopContext .custom-select.linkingToBox.flexColumn.flex1.br10.pad6 .optionDatabaseSelectChevron {
  margin-left:8px;
  margin-right:0px;
}

.loopContext .custom-select-head.pad6.flexRow.aCenter .selected-option {
  font-size:12px;
}

.loopOptionField {
  padding:0px;
}

.loopOptionField .optionConditionTableSelectorFilterItemContext {
  border: 1px solid #e2e2e2; 
}






.modalWrapper {
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  position:fixed;
  z-index:999;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(0,0,0,0.60);
}
.modalItem {
  width:50%;
  height:80vh;
  display:flex;
  flex-direction:column;
  background-color:#FFFFFF;
  overflow-y:auto;
  border-radius:4px;
}

.detailScreen {
  flex:1;
  display:flex;
  flex-direction: column;
  overflow-y:auto;
}
.pageContainer {
  flex:1;
  display:flex;
  flex-direction:row;
  overflow-y:auto;
}

.sideMenu {
  gap:10px;
  padding:30px;
  display:flex;
  flex-direction:column;
  justify-content: flex-start;
}

.sideMenuLogo {
  font-size:14px;
  font-weight:600;
  padding:0px 15px;
  margin-bottom:20px;
  display:none;
}
.sideMenuLogo span {
  color:#b400ff;
}
.sideMenuItem {
  padding:8px 15px;
}
.sideMenuItem.active {
  font-weight:500;
  border-radius:8px;
  background-color:#F4F4F4;
  border: 1px solid #dbdce3; 
  box-shadow:0px 2px 1px rgba(0,0,0,0.08);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.mainContainer {
  flex:1;
  display:flex;
  align-items:center;
  flex-direction:column;
  overflow-y:auto;
  background-color:#f6f6f6;
  box-shadow: inset 0px 0px 6px rgba(0,0,0,0.10);
}

.containerAdjust {
  max-width:100%;
}

.w600 {
  width:600px;
}

.w900 {
  width:900px;
}

.pageTitle {
  padding:30px 0px;
  margin-bottom:30px;
  border-bottom:1px solid #e8e8e8;
}

.pageTitle div {
  font-size:24px;
  font-weight:500;
  margin-bottom:10px;
}

.projectIco {
  width:80px;
  height:80px;
  margin:15px;
  border-radius:20px;
  position:relative;
  overflow:hidden;
}

.projectIco img {
  flex:1;
  width:100%;
  height:100%;
  display:flex;
  object-fit:cover;
  object-position:center;
}
.overlayChange {
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:rgba(0,0,0,0.60);
  position:absolute;
  display:none;
}
.overlayChange .minLightButton {
  padding:6px 10px;
}

.projectIco:hover .overlayChange {
  display:flex;
}


.projectMeta {
  display:flex;
  border-radius:20px;
  border:1px solid #e8e8e8;
}
.pmContext {
  flex:1;
  display:flex;
  flex-direction:column;
  border-left:1px solid #e8e8e8;
  padding:15px;
  justify-content: center;
}

.mLabel {
  color:#959595;
  font-size:12px;
  font-weight:500;
}

.tLabel {
  font-size:12px;
  font-weight:500;
  color:#171717;
}

.mValue {
  font-size: 14px;
}

.mInput {
  flex:1;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  background: #fcfcfc;
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.borderBox {
  border-radius:12px;
  background-color:#ffffff;
  border:1px solid #e8e8e8;
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}
.borderItem {
  gap:4px;
  padding:15px;
  display:flex;
  flex-direction:column;
  border-bottom:1px solid #e8e8e8;
}

.borderBox .borderItem:nth-last-child(1) {
  border-bottom-width:0px;
}

.removeFilterButton {
  font-size: 12px;
  text-align: center;
  color: #888;
}

.linkingBoxItem .calcItemDatabaseFilterLabel , .linkingBoxItem .calcItemDatabaseFilterOptions {
  font-weight:300;
}

.apiTabItem {
  margin:6px;
  border-radius:8px;
  background-color:#FFFFFF;
}
.apiTabItem .calcItemHeadTabItem {
  padding:0px 10px;
  border-radius:12px;
}

.apiTabItem .calcItemHeadTabItem.active {
  color:#171717;
  background-color:#f5f5f5;
}


.teamSearch {
  flex:1;
  width:100%;
  border-width:0px;
  padding:5px 15px;
  border-radius:8px;
  background-color:#fcfcfc;
  margin-top:10px;
  border:1px solid #e8e8e8;
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06)
}

.teamItem {
  display:flex;
  align-items:center;
  margin-top:15px;
}

.teamContext {
  flex:1;
  display:flex;
  align-items: center;
}

.teamDetails {
  flex:1;
  display:flex;
  flex-direction:column;
}

.teamIco {
  width:40px;
  height:40px;
  object-fit:cover;
  margin-right:15px;
  border-radius:999px;
  background-color:#fcfcfc;
  border:1px solid #e8e8e8;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size:12px;
  font-weight:500;
}

.teamName {
  font-size:14px;
}

.teamSubline {
  font-size:12px;
  color:#888888;
}

.teamRemove {
  width:25px;
  height:25px;
  border-radius:8px;
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
  border:1px solid #e8e8e8;
}

.teamRemove div {
  width:8px;
  height:1px;
  position:absolute;
  background-color:#434343;
  transform:rotate(45deg);
}
.teamRemove div:nth-last-child(1) {
  transform:rotate(-45deg);
}

.minActionRow {
  gap:12px;
  display:flex;
  align-items: flex-end;
  justify-content: space-between;
}
.minAdjust {
  margin-top:8px;
}

.minButton {
  font-size:12px;
  font-weight:500;
  color:#FFFFFF;
  padding:6px 14px;
  border-radius:8px;
  background-color:#212121;
  border:1px solid #e8e8e8;
  border: 1px solid #dbdce3;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.mbRed {
  background-color:#A80000;
}

.mbGray {
  background-color:#888888;
}

.minLightButton {
  cursor:pointer;
  font-size: 12px;
  font-weight: 500;
  color: #434343;
  padding: 6px 14px;
  border-radius: 8px;
  background-color: #FFFFFF;
  border:1px solid #e8e8e8;
  display:flex;
  align-items: center;
  border: 1px solid #dbdce3;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.publishRow {
  display:flex;
  align-items: flex-end;
  justify-content: space-between;
}

.publishAction {
  font-size: 12px;
  font-weight: 500;
  color: #434343;
  padding: 6px 14px;
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  margin-top:-12px;
}

.publishDetails {
  flex:1;
  gap:15px;
  padding:15px;
  margin-top:12px;
  border-radius:12px;
  display:flex;
  flex-direction:column;
  border:1px solid #e8e8e8;
  background-color:#f5f5f5;
  border: 1px solid #dbdce3;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.publishDetails .mInput {
  background-color:#FFFFFF;
}

.pbRow {
  gap:15px;
  display:flex;
  flex-direction:row;
}

.pbItem {
  flex:1;
  gap:4px;
  display:flex;
  flex-direction:column;
}

.pbLabel {
  font-size:12px;
  color:#434343;
}

.mValue span {
  color:#888888;
}

.pbDomainRow {
  display:flex;
  flex-direction:row;
  align-items: center;
}
.pbDomain {
  padding-left:15px;
}

.domainLabel {
  color: #434343;
  font-size: 12px;
}

.domainLabel.success {
  color:#10b507;
}

.domainLabel.fail {
  color:#A80000;
}

.usLabel {
  font-size:24px;
}

.dataTable {
  border-radius:12px;
  border:1px solid #e8e8e8;
  border: 1px solid #dbdce3; 
  background-color: #FFFFFF; 
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}


.dataTable table {
  width:100%;
}

.dataTable th {
  font-size:12px;
  color: #888888;
  font-weight:400;
  padding:10px 10px;
}

.dataTable thead th:nth-child(1) {
  border-radius:12px 0px 0px 0px;
}
.dataTable thead th:nth-last-child(1) {
  border-radius:0px 12px 0px 0px;
}

.dataTable td {
  font-size:14px;
  padding:10px 10px;
  border-top:1px solid #e8e8e8;
  border-right:1px solid #e8e8e8;
}

.dataTable tr td:nth-last-child(1) , .dataTable.removeBorder tr td:nth-last-child(2) {
  border-right-width:0px;
}

.tableData {
  gap:8px;
  display:flex;
  white-space: nowrap;
}

.tdDropdown {
  flex:1;
  display:flex;
  flex-direction: row;
  align-items: center;
  background-color:#f5f5f5;
  padding:8px 15px;
  border-radius:8px;
}

.tdDropdownIco {
  width:14px;
  height:14px;
  margin-right:10px;
  object-fit: contain;
}

.tdDropdownLabel {
  flex:1;
  display:flex;
  font-size:14px;
}

.tdDropdown.add .tdDropdownLabel {
  color:#10b507;
}

.tdDropdown.edit .tdDropdownLabel {
  color:#ED8849;
}

.tdDropdownChev {
  width:14px;
  min-width:14px;
  height:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
  margin-left:5px;
}

.tdDropdownChev div {
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  margin:auto;
  position:absolute;
  background-color:#434343;
}

.tdDropdownChev div.one {
  width:6px;
  height:1px;
  margin-right:2px;
  transform:rotate(-45deg);
}
.tdDropdownChev div.two {
  width:6px;
  height:1px;
  margin-left:2px;
  transform:rotate(45deg);
}

.tdInput {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 15px;
  border-radius: 8px;
  border:1px solid #e8e8e8;
  background-color: #ffffff;
}

.switchInput {
  width:40px;
  height:25px;
  border-radius:999px;
  background-color:#F5F5F5;
  display:flex;
  align-items: center;
  padding:0px 3px;
}
.switchInput.active {
  justify-content: flex-end;
  background-color:#10b507;
}
.switchBall {
  width:20px;
  height:20px;
  border-radius:999px;
  background-color:#FFFFFF;
}

.triggerWrapper {
  background-color:#F5F5F5;
}
.triggerContainer {
  width:300px;
  margin:auto;
  max-width:100%;
  display:flex;
  flex-direction:column;
  align-items: center;
  padding:20px;
}

.conditionalWrapper {
  padding:15px;
  margin:0px 15px;
  border-radius:10px;
  background-color:#FFFFFF;
  box-shadow:0px 3px 6px rgba(0, 0, 0, 0.16);
}

.cnDropdown {
  display:flex;
}

.conditionHead {
  display:flex;
  align-items: center;
  justify-content: space-between;
  background-color:#FFFFFF;
  padding:14px 14px;
  border-radius:10px;
}

.conditionHead.active {
  border-radius:10px 10px 0px 0px;
}

.conditionHeadContext {
  display:flex;
  align-items: center;
}

.conditionTitle {
  font-size:12px;
  font-weight: 600;
  color: #565656;
}

.conditionAction {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 999px;
  background-color: #f5f5f5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.08);
  position:relative;
}

.conditionAction div {
  width: 8px;
  height: 1px;
  position: absolute;
  background-color: #8d8d8d;
}

.triggerCondition {
  border-radius:10px;
}

.tdCheck {
  width:18px;
  height:18px;
  border-radius:4px;
  margin-right:10px;
  border:2px solid #f5f5f5;
}

.triggerSeperator {
  width:4px;
  height:20px;
  background-color:#FFFFFF;
  box-shadow:0px 0px 6px rgba(0,0,0,0.18);
}

.triggerContainer .triggerCondition {
  width:100%;
}

.triggerContainer .linkingBoxMain {
  width:100%;
}

.tdRadio {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  border-radius: 4px;
  position: relative;
}

.tdRadio.active .tdRadioDecor {
  background-color:#434343;
}

.tdRadioDecor {
  width:14px;
  height:14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f7f7f7;
  border-radius:3px;
}

.active .tdRadioDecor::before {
  content: "";
  width: 1px;
  height: 6px;
  margin-left: 2px;
  position: absolute;
  background-color: #ffffff;
  transform: rotate(45deg);
}

.active .tdRadioDecor::after {
  content: "";
  width: 1px;
  height: 3px;
  margin-left: -4px;
  margin-top: 2px;
  position: absolute;
  background-color: #ffffff;
  transform: rotate(-45deg);
}

.saveTrigger {
  flex:1;
  font-size:14px;
  font-weight:500;
  padding:10px 20px;
  margin-top:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction:column;
  background-color:#FFFFFF;
  border-radius:999px;
  border:1px solid #e8e8e8;
}

.triggerCondition .calcItemBody .calcItemDecor {
  display:none;
}
.triggerCondition .calcItemBody .calcItemBodyMain {
  padding:14px;
  background-color:#f5f5f5;
  border-top:1px solid #e8e8e8;
  /* box-shadow:inset 0px 0px 6px rgba(0,0,0,0.20); */
}
.triggerCondition .calcItemBody .calcItemBodyMain .calcItemUnit {
  margin-top:0px;
}

.tableBar {
  margin-bottom:30px;
}

.removeButton {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: #A80000;
  padding: 6px 14px;
  border-radius: 12px;
  background-color: #FFFFFF;
  border: 1px solid #e8e8e8;
}

.restoreButton {
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  color: #ED8849;
  padding: 6px 14px;
  border-radius: 12px;
  background-color: #FFFFFF;
  border: 1px solid #e8e8e8;
}

.dataTableActionRow {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom:20px;
}

.dataTableActionRow .minLightButton {
  background-color:#f5f5f5;
}

.buttonPlus {
  width:12px;
  height:12px;
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
  margin-top:-2px;
  margin-right:6px;
}
.buttonPlus div {
  width:8px;
  height:1px;
  position:absolute;
  background-color:#434343;
}
.buttonPlus div:nth-last-child(1) {
  transform:rotate(90deg);
}

.optionItemListLabel {
  width:110px;
  padding:8px;
  display:flex;
  align-items: center;
  position:relative;
}

.optionItemListLabelIco {
  right:10px;
  width:18px;
  height:18px;
  font-size:12px;
  position:absolute;
  border:1px solid #bdbdbd;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:999px;
}


.textAlignUnit {
  height:38px;
  display:flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  box-shadow:0 1px 1px rgba(0, 0, 0, .06);
}

.taItem {
  flex:1;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
}

.taItemIco {
  width:16px;
  height:16px;
}

.taItem .taItemIco {
  opacity:0.1;
}

.taItem.active .taItemIco {
  opacity:0.7;
}

.builderFooterControls {
  left: 342px;
  right: 410px;
  bottom: 0px;
  display:flex;
  position: fixed;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.builderDatabaseHeader {
  display:flex;
}

.builderDatabaseWrapper {
  flex:1;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}

.databaseAnchorAction {
  display:flex;
  align-items: center;
  padding:4px 4px;
  border-radius:999px;
  background-color:#fbfbfb;
  border:1px solid #e8e8e8;
  margin-bottom: 15px;
}

.daaDecor {
  width:20px;
  height:20px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:999px;
  background-color:#f1f1f1;
  margin: 6px 0px 6px 6px;
  color:#bdbdbd;
}

.daaDecorIco {
  width:14px;
  height:14px;
}

.daaLabel {
  padding:0px 10px;
}

.builderDatabase {
  width:100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.10);
}

.builderDatabase .dataTable {
  margin:0px 16px;
  overflow-x:auto;
  white-space: nowrap;
}

.builderDatabase .dataTable th input {
  flex:1;
  display:flex;
  width:100%;
  padding: 6px 10px;
  border-radius: 8px;
  border:1px solid #dddddd;
  background-color: #fdfdfd;
}

.builderDatabase .dataTable th {
  border-right:1px solid #e8e8e8;
}

.builderDatabase .upHeaderRow {
  display:none;
}

.builderDatabase .databaseBar {
  padding: 15px 15px;
}

.builderDatabase .paginationBar {
  display: none;
  padding:15px 30px;
}

.builderDatabase .paginationBar .paginationRight {
  display:none;
}

.builderDatabase .upHeadOptionLabel {
  color:#212121;
}

.paginationLeft {
  display:flex;
  flex-direction:row;
  align-items: center;
}

.paginateIco {
  width:10px;
  height:10px;
  opacity:0.8;
}

.paginationBar {
  padding:16px;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.paginateItem {
  width:30px;
  height:30px;
  background-color:#FFFFFF;
  border-width:0px;
  border-radius:6px;
  border:1px solid #dbe0e6;
}

.paginateItem.disabled img {
  opacity:0.1;
}

.paginateItem.previous {
  transform:rotate(180deg);
}

.builderDatabase tbody tr:nth-last-child(1) {
  border-bottom-width: 0px;
}

.currentPage {
  padding:0px 10px;
}

.dbpanelTableHolder {
  display:flex;
  flex-direction:column;
  padding:16px;
}

.dbpanelTableHolderInner {
  gap:12px;
  display:flex;
  flex-direction:row;
  align-items: center;
}

.dbpanel-sidebar-table-item {
  padding:6px 12px 6px 28px;
  background-color:#FFFFFF;
  border-radius:8px;
  position:relative;
  border:1px solid #ebebeb;
  box-shadow:0px 1px 1px rgba(0,0,0,0.06);
}

.dbpanel-sidebar-table-item.active {
  color:#FFFFFF;
  background-color:#212121;
}

.dbpanel-sidebar-table-item:before {
  content:'';
  width:8px;
  height:8px;
  position:absolute;
  top:0px;
  left:10px;
  bottom:0px;
  margin:auto;
  border-radius:999px;
  background-color:#F5F5F5;
  border: 1px solid #e8e8e8;
}

.dbpanel-sidebar-table-item.active:before {
  background-color:#FFFFFF;
}

.builderDatabaseInner {
  padding:16px 16px 0px 16px;
  display:flex;
  flex-direction:row;
  align-items:flex-end;
  justify-content: space-between;
}

.panelDatabaseSelector {
  display:flex;
  flex-direction:column;
}

.databaseRowOperationActions {
  gap:12px;
  display:flex;
  flex-direction:row;
}

.databaseRowOperationActions div {
  appearance: none;
  background-color: #FAFBFC;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0, rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292E;
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
  list-style: none;
  padding: 5px 15px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.panelDatabaseSelector .custom-select .optionInputIconBoxField {
  height:32px;
  color:#000000;
  padding:0px 15px 0px 15px;
}

.panelDatabaseSelector .custom-select .optionDatabaseSelectChevron {
  filter:invert(1);
  width:8px;
  height:8px;
}

.pdsLabel {
  font-size:12px;
  margin-bottom:6px;
}

.panelDatabaseSelector .optionInputIconBox {
  box-shadow: 0px 1px 1px rgba(0,0,0,0.06);
}

.userPanel {
  flex:1;
  display:flex;
  flex-direction:row;
  height:calc(100vh);
}

.upSidebar {
  flex: 1;
  width: 322px;
  min-width: 322px;
  max-width: 322px;
  padding:32px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-right: 1px solid #e8e8e8;
}

.upsMain , .upsSub {
  gap:20px;
  display:flex;
  flex-direction: column;
}

.upsMain .xoloLogo {
  margin:0px 0px 30px 15px;
}

.upLabel {
  font-weight:600;
  padding: 0px 14px;
}

.upComboPack {
  gap:14px;
  display:flex;
  flex-direction: column;
}

.upComboItem {
  gap:10px;
  padding:7px 14px;
  display:flex;
  flex-direction:row;
  align-items: center;
  border-radius: 8px;
  color: unset;
}

.upComboItem.active {
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
}

.upComboIco {
  width:18px;
  height:18px;
}

.upComboLabel {
  font-size:14px;
}

.subMenuWrapper {
  padding-left:40px;
}

.subMenuWrapper .upComboIco {
  display:none;
}

.upMain {
  flex:1;
  display:flex;
  flex-direction:column;
  background-color:#F5F5F5;
  box-shadow:0px 0px 6px rgba(0,0,0,0.10);
  position:relative;
  overflow:hidden;
  position: relative;
}

.upHeader {
  background-color:#FFFFFF;
}

.upHeaderTop {
  border-bottom: 1px solid #e8e8e8;
}

.upHeaderRow {
  padding:30px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.upBody {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.upHeaderContext {
  flex:1;
  display:flex;
  flex-direction: column;
}

.upTitle {
  font-size:18px;
  font-weight:500;
}

.upSubtitle {
  margin:4px 0px;
  font-size:14px;
  font-weight:400;
}

.upAction {
  gap:10px;
  display:flex;
  color:#FFFFFF;
  font-size:12px;
  border-radius:6px;
  padding: 10px 20px;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  background-color:#212121;
}

.upTabs {
  gap: 28px;
  padding:0px 30px;
  display:flex;
  flex-direction:row;
  margin-top:-20px;
}

.upTabsItem {
  color:#bdbdbd;
  padding:14px 2px;
  border-bottom:2px solid #FFFFFF;
}

.upTabsItem.active {
  color:#000000;
  border-color:#000000;
}

.upProjectList {
  gap: 20px;
  padding:30px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.upProjectItem {
  color:initial;
  border-radius: 12px;
  background-color:#FFFFFF;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

a.upProjectItem:hover {
  color:initial;
}

.upiHeader {
  gap:15px;
  padding:20px;
  display:flex;
  flex-direction:row;
  align-items: center;
}

.upiIco {
  width:60px;
  height:60px;
  border-radius:12px;
  background-color:#eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #a0a0a0;
}

.upiIco img {
  border-radius:12px;
  object-fit: cover;
  object-position: center;
}

.upiTitle {
  font-weight:500;
}

.upiSubline {
  color:#bdbdbd;
}

.upiBody {
  gap:16px;
  display:flex;
  flex-direction: column;
  padding:0px 20px;
}

.upiPublishRow {
  gap: 12px;
  display:flex;
}

.upiPublishItem {
  gap:6px;
  padding:6px 10px 6px 6px;
  display:flex;
  flex-direction: row;
  align-items: center;
  border:1px solid #e8e8e8;
  border-radius:999px;
}

.upiPublishItemLabel {
  font-size:12px;
  padding:0px 6px;
}

.upiPublishItemDecor {
  width:20px;
  height:20px;
  border-radius:999px;
  background-color:#eeeeee;
  display:flex;
  align-items: center;
  justify-content: center;
}

.upiPublishItemDecor.web.active {
  background-color:#FF5722;
}

.upiPublishItemDecor.ios.active {
  background-color:#212121;
}

.upiPublishItemDecor.android.active {
  background-color:#00bca3;
}

.upiPublishItemDecor.active .upiPublishItemIco {
  opacity:1;
  filter:invert(1);
}

.upiPublishItemIco {
  width:14px;
  height:14px;
  opacity:0.2;
}

.upiDescription {
  height:41px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.upiFooter {
  padding:20px;
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}

.upiFooterLeft {
  gap:12px;
  display:flex;
  flex-direction:row;
  align-items: center;
}

.openApp {
  display:flex;
  color:initial;
  font-size:12px;
  padding:10px 28px;
  border-radius:6px;
  background-color:#FFFFFF;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.viewApp {
  display: flex;
  font-size: 12px;
  padding: 10px 28px;
  border-radius: 6px;
  background-color: #eeeeee;
  border:1px solid transparent;
}

.upHeaderBottom {
  display:flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:1px solid #e8e8e8;
  padding:15px 30px;
  display: none;
}

.projectSearch {
  position:relative;
}

.projectSearchIco {
  top:0px;
  left:14px;
  bottom:0px;
  margin:auto;
  width:16px;
  height:16px;
  position:absolute;
}

.projectSearchInput {
  height: 40px;
  width: 260px;
  padding:10px 20px 10px 35px;
  border:1px solid #eee;
  border-radius:8px;
}

.fullDataTable {
  flex:1;
  display:flex;
  flex-direction:column;
}

.fullDataTable table {
  background-color:#FFFFFF;
}

.fullDataTable table thead {
  font-size:12px;
  font-weight:500;
  background-color:#f6f7f9;
  border-bottom:1px solid #dbe0e6;
}

.fullDataTable table thead th {
  font-weight:500;
  padding:10px 15px;
  border-left:1px solid #dbe0e6;
}

.fullDataTable table tbody td {
  max-width:400px;
  padding:0px 0px 0px 15px;
  height: 42px;
  position: relative;
  border-left:1px solid #dbe0e6;
}

.fullDataTable table thead th:nth-child(1) , .fullDataTable table tbody tr td:nth-child(1) {
  padding-left: 30px;
  border-left-width:0px;
}

.fullDataTable table tbody tr {
  background-color:#FFFFFF;
  border-bottom:1px solid #dbe0e6;
}

.usersScreen .upBodyInner {
  flex:1;
  display:flex;
  flex-direction: column;
}

.userInfo {
  gap: 12px;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.userInfoIco {
  width:24px;
  height:24px;
  color:#bdbdbd;
  font-size:10px;
  border-radius:999px;
  background-color:#eeeeee;
  position:relative;
  display:flex;
  align-items: center;
  justify-content: center;
}

.tableLetter {
  color: #979797;
  font-weight:500;
}

.projectsIcoList {
  gap:6px;
  display:flex;
  flex-direction:row;
  align-items: center;
}

.projectIcoListItem {
  width:24px;
  height:24px;
  border-radius:6px;
  background-color:#eeeeee;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #a0a0a0;
}

.tableRowify {
  display:flex;
}

.cpGray {
  background-color:#dee3e7;
}

.cpGreen {
  background-color:#c6f1db;
}

.cpBlue {
  background-color:#d8e4ff;
}

.capsuleItem {
  padding:2px 8px;
  border-radius: 6px;
}

.sideToggle {
  width:40px;
  height:40px;
  margin-left:10px;
  border-radius:14px;
  background-color:#ffffff;
  border:1px solid #e8e8e8;
  gap:6px;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sideToggleItem {
  width:20px;
  height:2px;
  border-radius:1px;
  background-color:#bdbdbd;
}


.userHeaderDropdown {
  width:40px;
  height:40px;
  color:#a0a0a0;
  font-weight:400;
  margin-right:10px;
  border-radius:999px;
  background-color:#eeeeee;
  border:1px solid #e8e8e8;
  display:flex;
  align-items: center;
  justify-content: center;
  position:relative;
}

.upModal {
  width:100%;
  height:100%;
  position:absolute;
  background-color: rgba(0,0,0,0.05);
  display:flex;
  flex-direction: column;
  align-items: flex-end;
}

.upModal.fixed {
  position:fixed;
  inset: 0;
}

.upModalItem {
  width:45%;
  min-width:45%;
  height:100%;
  display:flex;
  overflow-y:auto;
  flex-direction: column;
  background-color:#FFFFFF;
  box-shadow:0px 0px 6px rgba(0,0,0,0.20);
}

.upmPreHead {
  gap:12px;
  display:flex;
  flex-direction: row;
  align-items: center;
  padding:15px 40px;
}

.upmCloseIco {
  width:20px;
  height:20px;
}

.upmBody {
  flex:1;
  padding:45px;
  display:flex;
  flex-direction: column;
}

.upmBody h1 {
  font-size:18px;
}

.upmMainRow {
  gap:16px;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.upmUserAvatar {
  width:50px;
  height:50px;
  color:#a0a0a0;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:999px;
  background-color:#eeeeee;
}

.userTitleLarge {
  font-size:18px;
  font-weight:500;
}

.upmRow {
  display:flex;
  padding:15px 0px;
  align-items: flex-start;
  border-bottom:1px solid #e8e8e8;
}

.upmRowGroup {
  flex:1;
  display:flex;
  flex-direction: column;
}

.upmRowLabel {
  color:#212121;
  font-size:12px;
  font-weight:500;
  width:200px;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.upmRowValue {
  flex:1;
  display:flex;
}

.upmBody .upmRow:nth-last-child(1) {
  border-bottom:1px solid #e8e8e8;
}

.upmBody .upmRow.checkBorder {
  border-bottom-width: 0px;
}

.selectProjects {
  border-radius: 12px;
  border:1px solid #e8e8e8;
  background-color:#fbfbfb;
}

.selectProjectsLabel {
  margin-top: 50px;
  margin-bottom: 15px;
}

.selectProjectsHead {
  display:flex;
  flex-direction: column;
  position:relative;
  margin: 16px 16px 16px 16px;
  background-color: #FFFFFF;
  border-radius: 12px;
  border: 1px solid #e8e8e8;
}

.selectProjectsInput {
  border-width:0px;
  padding:8px 16px 8px 40px;
  background-color:transparent;
}

.selectProjectsBody {
  gap: 16px;
  padding:16px;
  display:flex;
  flex-direction: column;
  border-top:1px solid #e8e8e8;
  border-radius:0px 0px 12px 12px;
}

.projectLine {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.projectLineContext {
  flex:1;
  gap:12px;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.projectLineName {
  flex:1;
  display:flex;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.projectLineIco {
  width:36px;
  height:36px;
  color:#bdbdbd;
  border-radius:6px;
  background-color:#ffffff;
  border:1px solid #e8e8e8;
  display:flex;
  align-items: center;
  justify-content: center;
}

.defaultButton {
  color:initial;
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 7px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
}

.advanceButton {
  color:#2196F3;
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 7px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
}

.negativeButton {
  color:#A80000;
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 7px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
}

.darkButton {
  color:#FFFFFF;
  border: 1px solid #dbdce3;
  background-color: #000000;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.06);
  padding: 7px 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
}

.mpmActionRow {
  gap: 12px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding-top:45px;
  margin-top:45px;
  border-top:1px solid #eeeeee;
}

.upmRowInput {
  flex:1;
  display:flex;
  border-width:0px;
  align-items: center;
  background-color: #ffffff;
  padding: 12px 16px;
  border: 1px solid #dbdce3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
  /* margin-top: 12px; */
  border-radius: 8px;
}

textarea.upmRowInput {
  height:80px;
  resize:none;
}

.bgNote {
  padding: 15px;
  border-radius: 12px;
  border: 1px solid #dbdce3;
  background-color: #fbfbfb;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.selectBox {
  gap:12px;
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between;
  background-color:#f2f2f2;
  padding:2px 10px;
  border-radius:6px;
}

.selectIco {
  width:14px;
  height:14px;
}

.upmAppAvatar {
  width:120px;
  height:120px;
  font-size:18px;
  color:#a0a0a0;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:24px;
  background-color:#eeeeee;
  border:1px solid #e8e8e8;
  overflow: hidden;
}

.rowTopify {
  align-items: flex-start;
}

.upmAppAvatarAction {
  font-size:13px;
}

.borderBox .upmRow {
  padding:15px 15px;
}

.borderBox .upmRow:nth-last-child(1) {
  border-bottom-width:0px;
}

.appIconRow {
  display:flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.appIconRow .defaultButton {
  margin-top:15px;
  margin-right:15px;
}

.setHomeIco {
  width:16px;
  height:16px;
  margin-top:-2px;
  margin-left:-5px;
  margin-right:5px;
  opacity: 1;
}

.seoSettingsAction {
  gap:10px;
  display:flex;
}

.cancelSeo {
  padding:7px 10px;
}

.cancelIco {
  width:14px;
  height:14px;
}

.analyticsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 30px;
  background-color:#FFFFFF;
  justify-content: space-between;
  border-bottom: 1px solid #e8e8e8;
}

.analyticsTitle {
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.analyticsFilters {
  gap:20px;
  display:flex;
}

.analyticsFilters .custom-select-head.optionInputIconBox {
  width:180px;
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.analyticsFilters .custom-select-head .labelIco {
  width:30px;
  height:30px;
  border-radius:6px;
  background-color:#eeeeee;
  margin-left:6px;
  color: #a0a0a0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.analyticsFilters .custom-select-head.optionInputIconBox .optionInputIconBoxField {
  font-size:14px;
  font-weight:400;
}

.analyticsFilters .react-datepicker-wrapper {
  display:flex;
  padding:0px 8px 0px 40px;
  border-radius: 8px;
  align-items: center;
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
  background-size:16px 16px;
  background-repeat: no-repeat;
  background-position:16px 11px;
  background-image:url('../Assets/img/analytics/calendar.png');
}

.analyticsFilters .react-datepicker-wrapper input {
  font-weight:400;
  min-width:170px;
  border-width:0px;
}

.chartHead {
  display:flex;
  flex-direction:column;
  padding: 20px 20px 15px 20px;
}

.chartTitle {
  font-weight: 500;
}

.chartValueRow {
  display:flex;
  align-items: flex-start;
  flex-direction: column;
}

.chartAggregate {
  font-size:32px;
  font-weight:500;
}

.chartContainer {
  height:170px;
  padding:0px 20px;
  margin-top:20px;
  margin-bottom:20px;
}

.chartChangeRow {
  padding:20px;
  display:flex;
}

.chartChangeLabel {
  color: #4CAF50;
  padding:4px 14px;
  border-radius:8px;
  border: 1px solid #4CAF50;
  background-color:#4caf5012;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.chartChangeLabel.negative {
  color:#bc0000;
  border-color:#bc0000;
  background-color:#af4c4c12;
}

.analyticsMainBody {
  overflow-y:auto;
  background-color:#FFFFFF;
}

.analyticsDetailValue {
  padding:30px;
  font-size: 32px;
  font-weight: 500;
}

.mainChartHolder {
  padding:30px;
  border-bottom:1px solid #dbe0e6;
}

.mainChartHolder canvas {
  max-width:100%;
  max-height:560px;
}

.optionItem.mainCondition .optionHeadTabs {
  margin-left:0px;
  /* border: 1px solid #dbdce3;  */
  background-color:#FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.optionItem.mainCondition .optionHeadRight {
  display:none;
}

.rightActionItems {
  display:flex;
  margin-left:auto;
  align-items: center;
}

.hoverStatus {
  width:26px;
  height:26px;
  display: flex;
  font-size:12px;
  margin:0px 0px 0px 10px;
  border-radius:4px;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  box-shadow:0 1px 1px rgba(0, 0, 0, .06);
}

.hoverStatus.active {
  color:#FFFFFF;
  background-color:#212121;
}

.activeContainerTabStatus.active {
  color:#FFFFFF;
  background-color:#212121;
}

.dbHead {
  gap:12px;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.dbHeadMulti {
  gap:12px;
  display:flex;
  align-items: center;
}

.dbHeadSearchIco {
  width:14px;
  height:14px;
  object-fit: contain;
  opacity: 0.4;
  display: none;
}

.dbHeadSort {
  display:none;
}

.dbHeadAction .optionInputIconBoxField {
  display:none;
}

.dbHeadAction .optionDatabaseSelectChevron {
  margin-right:0px;
}

.dbRowCheck {
  margin-right:12px;
}

.tableSearchForm {
  display:flex;
  flex-direction: column;
}

.tableSearch {
  height:20px;
  border-width:0px;
  outline: 0px solid;
  background-color: transparent;
}

.databaseBar {
  display:flex;
  margin-top:-0px;
  align-items: flex-end;
  background-color:#FFFFFF;
  padding:0px 30px 30px 30px;
  justify-content: space-between;
}

.dbDataCell {
  min-width: 100%;
  max-width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.editCell {
  top:0px;
  right:6px;
  bottom:0px;
  margin:auto;
  width:14px;
  height:14px;
  content:'';
  position:absolute;
  background-size:14px 14px;
  background-repeat: no-repeat;
  background-image:url('../Assets/img/user/edit.png');
  display:none;
}

.fullDataTable table tbody td:hover .editCell {
  display: flex;
}

.editableCell {
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  position:absolute;
}

.editableCell form {
  display:flex;
  flex-direction: column;
}

.editableCell form textarea {
  z-index:999;
  color:#8d8d8d;
  height: 40.4px;
  min-height: 40.4px;
  max-height:82px;
  border-width:0px;
  border-radius:0px;
  padding-top:9px;
  padding-left:15px;
  padding-right:25px;
  outline: 0px solid;
  border:1px solid #397dff;
  outline: 2px solid #c7daff;

  --sb-track-color: #ffffff;
  --sb-thumb-color: #eeeeee;
  --sb-size: 4px;
}

.editableCell form textarea::-webkit-scrollbar {
  width: var(--sb-size);
}

.editableCell form textarea::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 2px;
}

.editableCell form textarea::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 0px;
}

.editCellConfirm {
  top:0px;
  right:10px;
  bottom:0px;
  width:20px;
  height:20px;
  margin:auto;
  z-index:9999;
  position:absolute;
  background-color:#eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:999px;
}

.editCellIco {
  width:14px;
  height:14px;
  object-fit: contain;
}

.dbSelector {
  position:relative;
}

.dbSelector .custom-select .optionInputIconBox {
  padding:7px 14px 7px 36px;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.dbSelector .custom-select .optionInputIconBox .upTitle {
  font-size:14px;
  font-weight:400;
}

.dbSelector .custom-select .optionDatabaseSelectChevron {
  margin-left:16px;
  margin-right:0px;
}

.upHeadOptionLabel {
  font-size: 12px;
  color: #9d9d9d;
  font-weight: 500;
  margin-bottom: 8px;
}

.dbSelectorType {
  width:20px;
  height:20px;
  z-index:999;
  display:flex;
  font-size:12px;
  font-weight:500;
  margin-top:8px;
  margin-left:8px;
  border-radius:4px;
  position:absolute;
  align-items: center;
  justify-content: center;
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.draggableDropdown {
  display:flex;
  border-radius:8px;
  position: relative;
  align-items: center;
  padding: 7px 14px 7px 6px;
  border: 1px solid #dbdce3;
  justify-content: space-between;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.draggableDropdown .optionDatabaseSelectChevron {
  margin-left:16px;
  margin-right:0px;
}

.draggableDropdownIco {
  width:20px;
  height:20px;
  margin-right:9px;
  object-fit: contain;
}

.dropdownElement {
  top:34px;
  left:0px;
  z-index:999;
  padding:6px;
  min-width:100%;
  overflow-y:auto;
  margin-top: 10px;
  max-height:216px;
  position:absolute;
  border-radius: 8px;
  background-color:#ffffff;
  border: 1px solid #dbdce3;
  box-shadow: 0px 3px 6px rgba(0,0,0,0.10);
}

.draggableAddSector {
  /* margin-bottom:6px; */
  border-radius: 8px;
  position: relative;
  padding: 10px 10px 10px 10px;
}

.draggableItem {
  gap: 12px;
  display:flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  position: relative;
  padding: 10px 10px 10px 10px;
}

.draggableDecorIco {
  width:14px;
  height:14px;
  min-width:14px;
  min-height:14px;
}

.draggableAddSector:hover {
  background-color:#eeeeee;
}

.draggableItem:hover {
  background-color:#eeeeee;
}

.draggableItemAction img {
  width:14px;
  height:14px;
  min-width:14px;
  min-height:14px;
  object-fit: contain;
}

.draggableItemLabel {
  flex:1;
  display:flex;
}

.dropdownElement::-webkit-scrollbar {
  width: 10px;
}

.dropdownElement::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.dropdownElement::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}

.databaseBarLeft {
  gap:20px;
  display:flex;
  flex-direction: row;
}

.databaseBarRight {
  gap:20px;
  display:flex;
  flex-direction: row;
}

.actionButton {
  gap:12px;
  display: flex;
  padding:7px 14px;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.actionButtonIco {
  width: 14px;
  height: 14px;
  object-fit: contain;
}

.dbActionRow {
  left:0px;
  right:0px;
  margin:auto;
  width:200px;
  bottom:30px;
  display:flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 14px;
  position:absolute;
  background-color:#FFFFFF;
  border: 0px solid #dbdce3; 
  border-radius:8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.dbActionRowContext {
  gap:8px;
  display:flex;
  align-items: center;
  padding:6px 10px;
  border-radius:8px;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upHeaderRow.aEnd {
  align-items: flex-end;
}

.primaryButtonize {
  color:#FFFFFF;
  background-color:#212121;
  box-shadow:none;
}

.primaryButtonize img {
  filter:invert(1);
}

.upHeaderRow {
  font-size:18px;
  font-weight:500;
}

.custom-select .options-list {
  padding:6px;
  min-width:100%;
  overflow-y:auto;
  margin-top: 10px;
  max-height:216px;
  position:absolute;
  border-radius: 8px;
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0px 3px 6px rgba(0,0,0,0.10);
  z-index:99;
}

.custom-select .options-list::-webkit-scrollbar {
  width: 10px;
}

.custom-select .options-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.custom-select .options-list::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}

.custom-select .options-list .option {
  flex:1;
  border-radius:8px;
  position: relative;
  padding:10px 34px 10px 10px;
}

.custom-select .options-list .option.active::before {
  content:'';
  top:0px;
  right:6px;
  bottom:0px;
  width:16px;
  height:16px;
  margin:auto;
  filter:invert(1);
  position:absolute;
  border-radius:999px;
  background-color:#FFFFFF;
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url('../Assets/img/user/check.png');
}

.custom-select .options-list .option:hover {
  background-color:#eeeeee;
}






.buttonBionic {
  padding: 7px 14px;
  border-radius: 8px;
  background-color: #FFFFFF;
  border: 1px solid #dbdce3;
  color: #545b6a;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.attachmentWrapper {
  flex: 1;
  gap: 12px;
  display:flex;
  flex-direction: column;
  align-items: flex-start;
}

.attachmentRow {
  gap: 20px;
  display:flex;
  flex-wrap: wrap;
  padding: 0px 0px;
}

.attachmentItem {
  position:relative;
}

.attachmentIco {
  width:80px;
  height:60px;
  object-fit:cover;
  border-radius: 12px;
  border:1px solid #dbdce3;
}

.attachmentRemove {
  top: -8px;
  right:-8px;
  width:20px;
  height:20px;
  display:flex;
  position:absolute;
  border-radius:999px;
  align-items: center;
  justify-content: center;
  background-color:#FFFFFF;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.attachmentRemoveIco {
  width:14px;
  height:14px;
  object-fit: contain;
}

.darkDefaultButton {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 14px 40px;
  border-radius: 8px;
  background-color: #212121;
}

.lightDefaultButton {
  font-size: 12px;
  font-weight: 500;
  padding: 14px 40px;
  border-radius: 8px;
  background-color: #eeeeee;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.ticketReply {
  display:flex;
  margin-top:20px;
  padding:15px 15px;
  border-radius:8px;
  flex-direction:column;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.ticketReplyTextarea {
  height:60px;
  resize: none;
  border-width:0px;
  outline:0px solid;
  margin-bottom:10px;
}

.ticketReply .darkDefaultButton {
  padding:9px 40px;
}

.ticketAttachmentItem {
  position: relative;
}

.statusHolder {
  display:flex;
}

.statusItem {
  color:#FFFFFF;
  font-size:12px;
  padding:6px 14px;
  border-radius:8px;
  background-color:#212121;
  text-transform: capitalize;
}

.upmBody .chatitem {
  padding:20px;
  margin-top:20px;
  border-radius: 8px;
  background-color:#f8f8f8;
}

.chatItemHead {
  gap:12px;
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.chatItemHeadAvatar {
  width:38px;
  height:38px;
  display:flex;
  font-size:12px;
  font-weight:500;
  border-radius:8px;
  align-items: center;
  justify-content: center;
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.chatItemHeadLabel {
  font-size:12px;
  font-weight:500;
}

.chatItemHeadTimeLabel {
  font-size:12px;
  color: #bdbdbd;
}

.upModal.centerModal {
  align-items: center;
  justify-content: center;
}

.upModal.centerModal .upModalItem {
  height: initial;
  border-radius:12px;
}

.upModal.centerModal .upModalItem .upmPreHead {
  border-bottom:1px solid #dbdce3;
}

.upModal.centerModal .upModalItem .upmBody {
  padding: 40px 40px;
  background-color:#f6f6f6;
}

.upModal.centerModal .upModalItem .upmBody .upmRowGroup.mt50 {
  margin-top:0px;
}

.upModal.centerModal .upModalItem .upmBody .mpmActionRow {
  margin-top:0px;
  margin-bottom:0px;
  padding-top: 40px;
  border-top-width:0px;
}

.stageRow {
  gap:40px;
  padding:20px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.stageRowItem {
  gap:10px;
  display:flex;
  align-items: center;
}

.stageNumber {
  width:30px;
  height:30px;
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:999px;
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.stretchDecor {
  flex:1;
  height:1px;
  display:flex;
  background-color:#eeeeee;
  display:none;
}

.stageRowItem.active .stageNumber {
  color:#ffffff;
  background-color:#212121;
}

.upModal.centerModal .upmAppAvatar {
  width:80px;
  height:80px;
  border-radius:16px;
}

.removeBorderBottom {
  border-bottom-width:0px !important;
}

.upModal.centerModal .upmPreHead {
  justify-content: center;
}

.upModal.centerModal .stageRow {
  padding:0px;
}

.upModal.centerModal .selectBox {
  background-color:#FFFFFF;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upModal.centerModal .upmRowGroup {
  border-radius:12px;
  background-color:#ffffff;
  border: 1px solid #dbdce3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upModal.centerModal .upmRowGroup .upmRow {
  padding:15px;
}

.upModal.centerModal .selectProjects {
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upmRow.aCenter {
  align-items: center;
}

.appIconModalRow {
  margin:15px;
}

.sdGap {
  gap:12px;
}

.upModal.centerModal .upmPreHead.customHeadAlign {
  justify-content: flex-start;
}

.upmAvatarWrapper {
  display:flex;
  align-items: center;
  justify-content: center;
  margin-bottom:30px;
}

.upmAvatarWrapper .upmAvatar {
  width:80px;
  height:80px;
  font-size:32px;
  border-radius:12px;
  background-color: #ffffff;
  border: 1px solid #dbdce3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upmRowGroup .upmRow:nth-last-child(1) {
  border-bottom-width:0px !important;
}

.builderCanvas {
  overflow:auto;
  height:calc(100vh - 150px);
}

.canvas-container {
  margin:auto;
  padding:20px !important;
  overflow:initial !important;
}

.canvasWidthSelector {
  top:0px;
  right:0px;
  height:42px;
  position: absolute;
  display: flex;
  align-items: center;
  padding: 4px 4px 4px 4px;
  border-radius: 999px;
  background-color: #fbfbfb;
  border: 1px solid #e8e8e8;
  margin-bottom: 15px;
}

.canvasWidthSelector input {
  width:110px;
  height: 100%;
  padding-left:10px;
  border-width:0px;
  outline:0px solid;
  background-color:transparent;
}

.canvasWidthSelector div {
  color:#bdbdbd;
  padding:4px 8px;
  border-radius:999px;
  background-color:#f1f1f1;
  border: 1px solid #e8e8e8;
}

.sectionFooter .container {
  background-color:#ffffff;
}

.colorUnitRow {
  display:flex;
  position: relative;
}

.colorUnitRow .optionItemRow {
  flex:1;
}

.colorUnitRow .optionItemRow .optionInputIconBoxText {
  font-size:12px;
}

.alignmentRow {
  gap:7px;
  display:flex;
}

.alignmentItem {
  flex:1;
  display:flex;
  min-width: 130px;
  flex-direction: column;
}

.alignmentList {
  display:flex;
  flex-direction: column;
}

.alignmentAction {
  gap:7px;
  display:flex;
  margin-top:7px;
  flex-direction: row;
}

.optionPrimary {
  flex:1;
  display:flex;
  color:#ffffff;
  font-size:12px;
  font-weight:500;
  padding:12px 12px;
  border-radius:8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color:#212121;
}

.optionSecondary {
  flex:1;
  display:flex;
  font-size:12px;
  font-weight:500;
  padding:12px 12px;
  border-radius:8px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color:#eeeeee;
}

.alignmentLabel {
  padding: 7px;
  color: #9d9d9d;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.options-list.customizeDropdown {
  max-height:initial;
}

.options-list.customizeDropdown .alignmentRow .alignmentItem .alignmentList .option.active::before {
  content:'';
  top:0px;
  right:6px;
  bottom:0px;
  width:16px;
  height:16px;
  margin:auto;
  filter:invert(1);
  position:absolute;
  border-radius:999px;
  background-color:#FFFFFF;
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url('../Assets/img/user/check.png');
}

.conditionDropdown {
  background-color:#f2f2f2;
  border: 0px solid #dbdce3;
  border-radius: 0px 0px 8px 8px;
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10); */
}

.conditionDropdown .calcItemBody {
  border-radius:0px 0px 8px 8px;
}

.conditionDropdown .calcItemDecor {
  margin:0px 20px;
}

.conditionDropdown .calcItemHead {
  border-bottom-width: 0px;
  padding: 10px 10px 0px 10px;
  background-color:transparent;
  border-top:1px solid #e8e8e8;
}

.optionConditionItemContext .optionConditionItemValueField {
  display:none;
}

.conditionDropdown .calcItemDecor {
  display:none;
}

.conditionDropdown .calcItemBodyMain {
  padding:14px;
  padding-top: 0px;
}

.conditionDropdown .calcItemHeadTab {
  background-color:transparent;
}

.conditionDropdown .calcItemHeadTabItem {
  border-radius:8px;
  background-color:#e8e8e8;
}

/* calculationWindow override stylings */

.calculationWindow .calcItemHeadTabItem.active {
  background-color: #FFFFFF;
  box-shadow: unset;
}

.calculationWindow .calcItemBody {
  border-radius: unset;
}

.calculationWindow .calcItemHead {
  border-top:0px solid #e8e8e8;
  background-color:#FFFFFF;
  border-bottom:1px solid #E8E8E8;
  border-bottom-width: 1px;
  padding:17px 20px;
}

.calculationWindow .calcItemDecor {
  display:block;
  min-width:1px;
  margin:0px 38px;
  background-color:#E2E2E2;
}

.calculationWindow .calcItemHeadTab {
  background-color:#F5F5F5;
}

.calculationWindow .calcItemHeadTabItem {
  background-color:unset;
}
/*  */

input::placeholder {
  color: var(--placeholder-color, gray);
}

.editableCellWrapper {
  gap:4px;
  top:0px;
  left:0px;
  right:0px;
  /* bottom:0px; */
  z-index:9;
  min-height:41px;
  max-height:82px;
  display:flex;
  flex-wrap:wrap;
  overflow-y: auto;
  padding: 6px 15px;
  position: absolute;
  align-items: flex-start;
  background-color:#ffffff;
  border: 1px solid #2196F3;
  outline: 2px solid #dbefff;
}

.editableMainBlock {
  gap:4px;
  display:flex;
  align-items: flex-end;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  flex-shrink: 1;
  min-width: 0;
}

.editable-span {
  padding:4px 8px;
  border-radius:6px;
  position: relative;
  z-index:999;
  
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  flex-shrink: 1;
  min-width: 0;
}

.editable-span.capsule{
  color: #212121;
  background-color: #e8e8e8;
}

.editable-span.active {
  color: #212121;
  padding: 4px 8px;
  outline: 0px solid;
  background-color: #e8e8e8;
}

.editable-span.active.last {
  color: #212121;
  padding: 4px 0px;
  outline: 0px solid;
  background-color: transparent;
}

.editable-span.capsule.empty {
  background-color: transparent;
}

.dbDataBlocks {
  gap:4px;
  display:flex;
  flex-direction: row;
  align-items: flex-end;
}

.ddbItem {
  color: #212121;
  padding: 4px 8px;
  border-radius: 6px;
  background-color: #e8e8e8;
}

.editableCellWrapper.selected .editable-span {
  outline:0px solid;
  background-color:transparent;
}

.editableCellWrapper.selected .editable-span::before {
  top:4px;
  left:8px;
  right:8px;
  bottom:4px;
  z-index:-2;
  content:'';
  position:absolute;
  background-color:#b4d7ff;
}

.editableCellWrapper.selected .editable-span.active::before {
  left:0px;
  right:0px;
}

.cellFileUpload {
  top:0px;
  right:10px;
  z-index:999;
  font-size:10px;
  color: #ffffff;
  cursor:pointer;
  padding:4px 8px;
  margin-top:-22px;
  position:absolute;
  border-radius:4px 4px 0px 0px;
  background-color:#2196F3;
}

.appxolo-switch {
  width: 60px;
  height: 30px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
  transition: background-color 0.3s ease;
}

.appxolo-switch.switch-on {
  background-color: #4caf50; 
}

.appxolo-switch .switch-handle {
  width: 26px;
  height: 26px;
  background-color: #eee;
  border-radius: 6px;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: left 0.3s ease;
}

.appxolo-switch.switch-on .switch-handle {
  left: 32px;
  background-color:#ffffff;
}

.builderscreen .xoloHeader .userHeaderDropdown, .builderscreen .xoloHeader .sideToggle {
  display: none;
}

.bionicContainerOptions {
  gap: 0px;
  display:flex;
  flex-direction: column;
}

.bionicOptions {
  flex:1;
  padding:7px;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.bionicHeadTabs {
  display:flex;
  padding:0px 2px;
  align-items: center;
  width: 82px;
  height: 30px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
  transition: background-color 0.3s ease;
}
.bionicHeadTabItem {
  width: 26px;
  height: 26px;
  min-width:26px;
  font-weight:500;
  font-size: 10px;
  border-radius:6px;
  display:flex;
  align-items: center;
  justify-content: center;
}

.bionicHeadTabs .bionicHeadTabItem.active {
  background-color:#eeeeee;
}

.bionicOptions .optionItemListLabel {
  padding:0px;
  width:initial;
}



.naRow {
  gap:10px;
  padding:7px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.naTabWrapper {
  gap:4px;
  padding:2px;
  display:flex;
  border-radius:6px;
  background-color:#eeeeee;
}

.naTabItem {
  width:26px;
  height:24px;
  display:flex;
  border-radius:4px;
  align-items: center;
  justify-content: center;
}

.naTabItem.active {
  background-color:#ffffff;
}

.naTabCanvas {
  gap:4px;
  display:flex;
}

.naTabItemIco.direction {
  height:14px;
}
.naTabItemIco.arrowLine {
  width:14px;
}
.naTabItemIco.arrowOnly {
  height:10px;
}

.naLabel {
  font-size: 12px;
  color: #9d9d9d;
  font-weight: 500;
}

.naDropdownLabel {
  display:flex;
  align-items: center;
}

.naDropdownLabel {
  gap: 10px;
  display:flex;
  flex-direction: row;
}

.naDropdownLabel .optionDatabaseSelectChevron {
  margin-right:0px;
}

.alignmentDropdownList {
  gap: 0px;
  display:flex;
  flex-direction: column;
  width: 190px !important;
  overflow: initial !important;
}

.alignmentDropdownList .alignmentAction {
  margin-top:0px;
}

.naDropdown {
  /* background: #eeeeee; */
  padding: 4px 0px;
  border-radius: 5px;
}

.dpoLabel {
  padding:7px;
  font-size:12px;
  display: none;
}

.rte-placeholder {
  top:3px;
  right: 0;
  bottom: 0;
  left: 14px;
  color: #676767;
  overflow: hidden;
  position: absolute;
}

.properties span.custom-span.active {
  background-color: green;
}

.properties span.custom-span {
  color: #FFFFFF;
  cursor: pointer;
  padding: 0px 4px;
  position: relative;
  border-radius: 6px;
  white-space: nowrap;
  display:inline-block;
  background-color: #434343;
  border: 0px solid #dbdce3;
}

.bionicContainerOptions .optionInputIconBoxField {
  height:28px;
}
.bionicContainerOptions .optionDatabaseSelectChevron {
  margin-right: 8px;
}
.bionicContainerOptions .optionInputIconBox {
  gap: 8px;
  border-radius:6px;
}

.mediaLibrary {
  right:0px; 
  display:flex;
  align-items: center;
  justify-content: center;
}

.mediaLibrary .calculationWindow {
  flex:1;
  top: initial;
  left: initial;
  display: flex;
  max-width:650px;
  position: initial;
  overflow: initial;
  flex-direction:column;
}

.mediaLibrary .calcHead {
  font-size:24px;
  font-weight: 500;
  border-bottom-width:0px;
  padding:20px 20px 0px 20px;
  flex-direction:row-reverse;
  background-color:transparent;
  justify-content: space-between;
}

.mediaLibrary .calcHead .calcHeadAction {
  box-shadow: none;
  background-color:#eeeeee;
}

.mediaLibrary .calcHeadAction .one {
  display:none;
}

.mediaLibrary .calcHeadAction .two {
  width:10px;
  height: 1px;
  transform:rotate(135deg);
  margin-top:auto;
  margin-left:auto;
}

.mediaLibrary .calcHeadAction .calculationHeadBackLineHorizontal {
  transform:rotate(45deg);
}

.mediaBar {
  padding:20px;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mediaFilterTabs {
  padding:4px;
  display:flex;
  border-radius: 6px;
  background-color:#FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.mediaFilterTabs div {
  padding:4px 10px;
}

.mediaFilterTabs div.active {
  border-radius: 6px;
  background-color:#eeeeee;
}

.mediaGalleryBodyWrapper {
  padding-right:20px;
}

.mediaGalleryBody {
  height: 360px;
  overflow-y:auto;
}

.mediaGalleryBody::-webkit-scrollbar {
  width: 10px;
}

.mediaGalleryBody::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.mediaGalleryBody::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}

.mediaGalleryGrid {
  gap:20px;
  display:flex;
  flex-wrap:wrap;
  padding: 0px 20px;
}

.mediaGalleryGrid .uploadPhotoItem {
  display:flex;
  align-items: center;
  justify-content: center;
  width:100px;
  height:100px;
  min-width:100px;
  min-height:100px;
  max-width:100px;
  max-height:100px;
  margin: initial;
  position: relative;
  border-radius:4px;
  background-color:#eeeeee;
}

.mediaGalleryGrid .uploadPhotoItem::after {
  top:8px;
  right:8px;
  width:14px;
  height:14px;
  content: '';
  border-radius:4px;
  position:absolute;
  background-color:#ffffff;
}

.mediaGalleryGrid .uploadPhotoItem.active::after {
  filter:invert(1);
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url('../Assets/img/user/check.png');
}

.mediaGalleryGrid .uploadPhotoItem.active::before {
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  content:'';
  border-radius:4px;
  position:absolute;
  border:2px solid #212121;
}

.mediaGalleryGrid .uploadPhoto {
  width:100px;
  height:100px;
  min-width:100px;
  min-height:100px;
  max-width:100px;
  max-height:100px;
  border-radius: 4px;
}

.uploadPhotoUnit {
  display:flex;
  font-size:12px;
  color: #FFFFFF;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  box-shadow: inset 0px 0px 1px rgba(0, 0, 0, 0.20);
}

label.uploadPhotoItem::after {
  display:none;
}

.iconItem .uploadPhoto {
  width:50px;
  height:50px;
  min-width:50px;
  max-width:50px;
  max-height:50px;
  min-height:50px;
}

.uploadPhotoItem.iconImgWrapper .uploadPhoto {
  width:50px;
  height:50px;
  min-width:50px;
  max-width:50px;
  max-height:50px;
  min-height:50px;
}

.mediaAction {
  display: flex;
  padding: 20px;
  align-items: center;
  border-top:1px solid #e8e8e8;
  justify-content: space-between;
}

.mediaSelectButton {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 14px 40px;
  border-radius: 8px;
  background-color: #212121;
}

.mediaSelectButton.disabled {
  background-color:#cecece;
}

.mediaSelectedLabel {
  color:#646464;
}

.mediaComplex {
  gap: 10px;
  display:flex;
  flex-direction: row;
}

.mediaSearch {
  position: relative;
  border-radius: 6px;
  background-color:#FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.mediaSearchIco {
  top:0px;
  left: 8px;
  bottom:0px;
  width:14px;
  height:14px;
  margin: auto;
  position: absolute;
}

.mediaSearchInput {
  width:130px;
  padding:0px;
  height:36px;
  padding-left:30px;
  border-width:0px;
  background-color:transparent;
}

.mediaColor {
  width:36px;
  height:36px;
  min-width:36px;
  min-height:36px;
  display:flex;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color:#FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.mediaColorInner {
  width:18px;
  height:18px;
  max-width:18px;
  max-height:18px;
  border-radius: 999px;
  background-color:#eeeeee;
}

.mediaFillDropdown {
  position: relative;
}

.mediaFillLabel {
  gap:8px;
  height: 36px;
  min-height: 36px;
  padding:0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.mediaFillLabel .optionDatabaseSelectChevron {
  margin-right:0px;
}

.mediaFillLabel {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mediaFillDecor {
  color:#bdbdbd;
}

.upAccountBody {
  gap:20px;
  padding: 30px;
  display: flex;
  overflow-y:auto;
  flex-direction: column;
}

.upaBox {
  gap:20px;
  display:flex;
  padding:20px;
  border-radius:12px;
  flex-direction: column;
  border: 1px solid #dbdce3;
  background-color: #FFFFFF;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upAccountBody > div {
  max-width: 50%;
}

.upaBoxRow {
  flex:1;
  gap:20px;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.upaBoxItem {
  flex:1;
  display:flex;
  flex-direction: column;
  position: relative;
}

.upaLabel {
  color: #212121;
  font-size: 12px;
  font-weight: 500;
  margin-bottom:6px;
}

.upaValue {
  font-size:16px;
  display:flex;
  align-items: center;
}

.upaBoxItem input {
  border-radius: 8px;
  padding: 12px 15px;
  border: 1px solid #dbdce3;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.fieldLockIco {
  width:16px;
  height:16px;
  right: 14px;
  bottom:16px;
  position:absolute;
}

.upaAvatar {
  width:80px;
  height:80px;
  display: flex;
  font-size:24px;
  color: #bdbdbd;
  border-radius:8px;
  align-items: center;
  justify-content: center;
  background-color:#eeeeee;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upaMain {
  font-weight: 500;
}

.upaActionMain {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  padding: 14px 40px;
  border-radius: 8px;
  background-color: #212121;
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upaNote {
  padding: 20px;
  border-radius: 12px;
  border: 1px solid #dbdce3;
  background-color: #fbfbfb;
  background-color: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upaUploadLine {
  font-size:12px;
  color:#4CAF50;
  margin-top:2px;
}

.upaDropdownLabel {
  display:flex;
  border-radius: 8px;
  padding: 12px 15px;
  align-items: center;
  border: 1px solid #dbdce3;
  justify-content: space-between;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.upaDropdownLabelText {
  gap: 8px;
  display:flex;
  align-items: center;
}

.planDetails {
  font-size:10px;
  padding:4px 10px;
  border-radius:4px;
  background-color:#eeeeee;
}

.billingList {
  /* background-color:#ffffff; */
  border-top:1px solid #e8e8e8;
}

.calculateIco {

}

.calcSourceLabel {
  /* display: none; */
}

.emptyBillingLabel {
  padding:20px 30px;
}

.woloEditor {
  flex:1;
  display:flex;
  max-width:100%;
  flex-direction: column;
}

.woloEditor .optionConditionTableFilterMetaQuery {
  flex:1;
  display:flex;
  flex-direction: column;
}

.calcItemDatabaseFilterBoxInnerRow {
  /* flex-direction: row; */
}

.calcItemUnitBox.filterWrapper {
  align-items: initial;
  flex-direction: column;
}

.calcItemDatabaseFilterBoxInnerRow .custom-select {
  flex:1;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor .spaceTopify {
  margin-top:0px;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor > div:first-of-type {
  flex:1;
  display:flex;
  flex-direction: column;
  margin-top:0px !important;
}

.calcItemDatabaseFilterBoxInnerRow .paramListRow {
  top:3px;
  margin-top:-4px;
  position: absolute;
  right:0px !important;
  border-top-width: 0px;
  padding:1px 1px 3px 1px !important;
}

.calculationWindow .calcItemDatabaseFilterBoxInnerRow .paramListRow {
  padding:2px 1px 3px 1px !important;
}

.linkingBoxMain .calculationWindow .calcItemDatabaseFilterBoxInnerRow .woloEditor.pastFocused .paramListRow {
  padding:2px 4px 3px 4px !important;
}

.linkingBoxMain .calculationWindow .calcItemDatabaseFilterBoxInnerRow .woloEditor.neverFocused .weInner .paramListRow .paramListItem {
  width:30px;
}

.calcItemDatabaseFilterBoxInnerRow .paramListRow .paramListItem  {
  background-color: transparent;
}

.calcItemDatabaseFilterBoxInnerRow .paramListRow .paramListItem .calculateIco {
  width:18px;
  height:18px;
}

.calcItemDatabaseFilterBoxInnerRow .rte-placeholder {
  top:7px !important;
  left: 10px !important;
  color: #434343;
  font-size:12px !important;
  font-weight: 300;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor .rich-text-editor {
  color: #434343;
  max-height:64px;
  overflow-y: auto;
  outline: 0px solid;
  padding-right: 28px;

  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  flex-shrink: 1;
  min-width: 0;
}

.woloEditor.pastFocused .rich-text-editor {
  min-height:64px;
  max-height:64px;
  overflow-y:auto;
  outline:0px solid;
}

.woloEditor.neverFocused .rich-text-editor {
  overflow:hidden;
  max-height:25px;
  min-height:initial;
  padding-right:45px;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor.neverFocused .rich-text-editor {
  padding-right:30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-height:32px !important;
  max-height:32px !important;
}

.linkingBoxMain .databaseOperationModule .woloEditor.neverFocused .rich-text-editor {
  color: initial;
  max-height:32px;
  padding-right:30px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.linkingBoxMain .databaseOperationModule .woloEditor .rte-placeholder {
  top: 7px;
  left: 10px;
  font-size:12px;
}

.linkingBoxMain .databaseOperationModule .woloEditor.neverFocused .paramListRow {
  gap:0px;
  right:6px;
  bottom:1px;
}

.linkingBoxMain .domBody .woloEditor.neverFocused .paramListRow {
  top:4px;
  right:6px !important;
  bottom:0px !important;
}

.linkingBoxMain .domBody .woloEditor.pastFocused .paramListRow {
  padding:0px 5px 1px 5px !important;
}

.linkingBoxMain .domBody .woloEditor.pastFocused .paramListRow .paramListItem {
  height:30px;
}



.linkingBoxMain .domBody .calculationWindow .woloEditor.neverFocused .paramListRow {
  top:3px !important;
  right:0px !important;
  padding:2px 1px 3px 1px !important;
}

.linkingBoxMain .domBody .calculationWindow .woloEditor.pastFocused .paramListRow {
  padding:0px 5px 2px 5px !important;
}

.domBody .calcItemDatabaseFilterBoxInnerRow .calcItemUnitMainDropdown .calcItemUnitMainDropdownLabel {
  color:#000000;
  min-height:32px;
  outline:0px solid;
}

.linkingBoxMain .domBody .custom-select .custom-select-head.calcItemUnitMainDropdown.spaceTopify {
  /* margin-top:0px; */
}

.linkingBoxMain .databaseOperationModule .woloEditor.neverFocused .paramListRow .paramListItem {
  width:20px;
  background-color:transparent;
}

.linkingBoxMain .databaseOperationModule .woloEditor.neverFocused .paramListRow .paramListItem .calculateIco {
  min-width:18px;
  min-height:18px;
}

.woloEditor.pastFocused .paramListRow {
  position:initial;
  padding: 0px 12px 4px 12px;
  border-top: 0px solid #eeeeee;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor.pastFocused .weInner {
  border-radius:8px;
  background-color:#f5f5f5;
}

.linkingBoxMain .databaseOperationModule .woloEditor.pastFocused .weInner {
  margin-top:0px;
  border-radius:8px;
  background-color:#f5f5f5;
}

.linkingBoxMain .databaseOperationModule .woloEditor.pastFocused .weInner .rich-text-editor {
  color:initial;
  min-height:42px;
  max-height:42px;
  padding-right:10px;
  padding-bottom:0px;
}

.linkingBoxMain .databaseOperationModule .woloEditor .weInner .rich-text-editor.calcDatabaseFilterMultiBox.spaceTopify {
  margin-top:0px;
  word-break:break-all;
}

.linkingBoxMain .databaseOperationModule .woloEditor.pastFocused .weInner .rich-text-editor.calcDatabaseFilterMultiBox.spaceTopify {
  color:#434343;
  margin-top:0px;
  outline:0px solid;
  min-height: 45px;
  max-height: 45px;
  padding-bottom:0px;
}

.linkingBoxMain .databaseOperationModule .woloEditor.pastFocused .weInner .paramListRow {
  gap:0px;
  padding:0px 5px 0px 5px;
}

.linkingBoxMain .databaseOperationModule .woloEditor.pastFocused .weInner .paramListRow .paramListItem {
  width:24px;
  padding:0px;
  background-color:transparent;
}

.linkingBoxMain .databaseOperationModule .woloEditor.pastFocused .weInner .paramListRow .paramListItem .calculateIco {
  width:18px;
  height:18px;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor.pastFocused .rich-text-editor {
  min-height:42px;
  max-height:42px;
  padding-right:7px;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor.pastFocused .paramListRow .paramListItem {
  height:26px;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor.neverFocused .paramListRow {
  position: absolute;
}

.calcItemDatabaseFilterBoxInnerRow .woloEditor.neverFocused .rte-placeholder {
  padding-right: 28px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.optionConditionTableSelectorFilterItemContext.lightenMode .woloEditor .rte-placeholder {
  top:2px;
  left:14px;
  font-size:14px;
}

.Map .calcDropdown.add {
  padding:0px;
  min-width:0;
}

.Map .calcDropdownLabel {
  word-wrap: break-word; overflow-wrap: break-word; white-space: pre-wrap; flex-shrink: 1; min-width: 0;
}

.Map .removeX {
  display: none;
}

.Map .optionItemRow {
  flex-direction: column;
}

.Map .woloEditor .paramListRow {
  right:4px;
  bottom:3px;
  padding: 0px;
  /* position: absolute; */
}

.Map .woloEditor .rich-text-editor {
  color:initial;
  min-height:36px;
  padding-top:9px;
  outline:0px solid;
}

.Map .woloEditor .calcDatabaseFilterMultiBox {
  min-height: 36px;
  font-weight: 500;
  max-height: 70px;
  padding-right: 34px;
  overflow-y: auto;
}

.Map .woloEditor .rte-placeholder {
  top: 9px;
  left:10px;
}

.calcItemDatabaseFilter::before {
  top:0px;
  left:20px;
  width:1px;
  height:100%;
  content:'';
  position: absolute;
  background-color:#e8e8e8;
}

.inputColorLabel {
  padding: 12px 7px 20px 7px;
}

.inputColorItem {
  padding:0px;
}

.optionItem.Content .optionHeadTabItem.active {
  color:#212121;
  background-color:#FFFFFF;
}

.container-bg-dropdown {
  top:30px;
  right:0px;
  position: absolute;
  padding: 6px;
  overflow-y: auto;
  margin-top: 10px;
  max-height: 216px;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #dbdce3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  width: 240px !important;
  overflow: initial !important;
  /* width:100%; */
  z-index:999;
}

.container-bg-dropdown .container-bg-upload {
  padding:6px;
}

.container-bg-dropdown .container-bg-upload-box {
  display: flex;
  font-size:12px;
  background: #eee;
  padding: 20px 0px;
  border-radius: 8px;
  align-items: center;
  border-width: 1px;
  border-style: dashed;
  border-color: #c6c6c6;
  justify-content: center;
}

.container-bg-dropdown .optionInputIconBox {
  border: 1px solid #dbdce3; 
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
  background-color:#ffffff;
}

.calculationWindow {
  top:0px;
  bottom:initial;
  margin:auto;
  right: 390px;
  width: 320px;
  z-index: 9;
  position: fixed;
  
}

.calculationWindow .calcHead {
  gap:15px;
  padding:15px;
  align-items: center;
  /* display: none; */
}

.calculationWindow .calcHeadMeta {
  align-items: flex-start;
}

.calculationWindow .calcHeadLabel {
  font-size:12px;
}

.calculationWindow .calcHeadSublineLabel {
  font-size:12px;
}

.calculationWindow .calcItemHead {
  gap:15px;
  padding:15px;
}

.calculationWindow .calcItemHeadTabItem {
  padding:0px 10px;
  border-radius:10px;
}

.calculationWindow .calcItemDecor {
  margin: 0px 17px 0px 32px;
}

.calculationWindow .calcItemBodyMainHeader {
  margin-top:0px;
}

.calculationWindow .calcItemUnit {
  border:1px solid #e8e8e8;
}

.calculationWindow .calcItemUnitFooter .calcItemUnitMain {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fileTypeWrapper img {
  width:18px;
  height:18px;
  margin-top: -2px;
  margin-right: 1px;
  border-radius:4px;
}

.doubleCopy {
  display:none;
}

.custom-span.active .doubleCopy {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99;
  display: flex;
  color: #ffffff;
  /* font-size: 12px; */
  /* font-weight: 500; */
  text-align: center;
  position: absolute;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background-color: #212121;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.20);
}

.linkingBoxMain .removeX {
  right:12px;
}

.linkingSelectDropdown {
  padding: 8px 12px;
  display:flex;
  border-radius:8px;
  align-items: center;
  background-color: #f5f5f5;
  justify-content: space-between;
}

.dateTypeSelector .linkingOpen {
  width:100%;
  border-radius:8px;
}

.dateTypeSelector .stillRadio.checkBoxify {
  border-radius:4px;
}

.dateTypeSelector .stillRadio.checkBoxify .optionDatabaseRadioDecor {
  border-radius:4px;
}

.dateTypeSelector .stillRadio.active .optionDatabaseRadioDecor {
  background-color:#434343;
}

.streamTypeWrapper {
  padding: 7px;
  margin: -14px 0px;
}

.linkDropdown .calcItemHeadTabItem.active {
  border-radius:8px;
  background-color:#ffffff;
}

.linkingSelectDropdownWrapper .options-list {
  left:12px;
  right:12px;
  z-index:9;
  position: absolute;
  background:transparent !important;
}

.linkingBoxWrapper .databaseOperationModule {
  box-shadow:none;
  border-radius:10px;
  border:1px solid #e2e2e2;
}

.rowOperationWrapper .databaseItemHead {
  border-bottom-width:0px;
}

.linkingBoxMain .rowOperationWrapper:nth-child(2) .databaseItemHead {
  border-top:1px solid #e8e8e8;
}

.rowOperationAdd {
  padding: 10px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  border-radius: 0px; 
  background-color: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  /* background-color:#212121; */
  /* color:#FFFFFF; */
}

.linkingComponent .calcItemDatabaseFilter::before {
  opacity: 0;
}

.linkingComponent .custom-select-head.calcDropdown {
  height:100%;
  padding:0px 12px;
}

.linkingAdjustDropdown .custom-select-head.optionInputIconBox {
  box-shadow: none;
  background-color:#f5f5f5;
}

.linkingAdjustDropdown .custom-select-head.optionInputIconBox .optionInputIconBoxField {
  font-weight:400;
}

.removeGap {
  gap:0px;
}

.linkingConditionBlock .revealOffset {
  margin-top:0px;
}

.linkingConditionBlock .optionCondition {
  border-radius:0px;
  border-top-width:0px;
}

.Content .customParameterDispatch {
  padding:0px;
  background-color:transparent;
}

.linkingComponent .emailTemplateUnit .customParameterDispatch {
  border-radius:8px;
  background-color:#f5f5f5;
}

.customParameterDispatch .rte-placeholder {
  color:#888888;
}

.emailTemplateUnit .calcItemUnitMainDropdown.spaceTopify {
  margin-top:0px;
}



.optionItem.Map .databaseItemHead {
  border-bottom-width:0px;
  background-color:initial;
}

.optionItem.Map .woloEditor .paramListItem {
  background-color: transparent;
}

.optionItem.Map .woloEditor.neverFocused .weMainWrapper > .calcDatabaseFilterMultiBox {
  max-height:20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.optionItem.Map .woloEditor.pastFocused .weMainWrapper > .calcDatabaseFilterMultiBox {
  padding-right:32px;
}

.optionItem.Map .linkingBoxItem {
  padding:7px 0px;
}

.optionItem.Map .mapIconBox .woloEditor.neverFocused .paramListRow {
  gap:0px;
  top:4px;
  padding-right:6px !important;
}

.optionItem.Map .mapIconBox .woloEditor.neverFocused .paramListRow .paramListItem  {
  width:22px;
  padding:0px 0px;
}

.optionItem.Map .mapIconBox .woloEditor.neverFocused .weMainWrapper > .calcDatabaseFilterMultiBox {
  padding-right:50px;
  overflow:hidden;
}

.optionItem.Map .mapIconBox .woloEditor.pastFocused .weInner > .paramListRow {
  gap:0px;
  position: initial;
  padding:1px 6px 3px 6px !important;
}

.optionItem.Map .mapIconBox .woloEditor.pastFocused .weInner > .paramListRow > .paramListItem {
  width:22px;
  padding:0px 0px;
}




.optionItem.Map .linkingBoxMain .optionItemBody > .mapLinkingContainer > .loopLinking {
  margin:7px;
  padding:0px;
  border-radius: 8px;
  border:1px solid #e8e8e8;
}

.optionItem.Map .linkingBoxMain .optionItemBody > .mapLinkingContainer > .loopLinking > .optionItemBody > .linkLabel {
  color: #888888;
  font-weight:400;
  padding:0px 6px;
  margin-bottom:6px;
}

.optionItem.Map .linkingBoxMain .optionItemBody > .mapLinkingContainer > .loopLinking > .optionItemBody > .linkingSelectDropdownWrapper > .linkingSelectDropdown {
  font-size: 12px;
  padding: 7px 12px;
}

.optionItem.Map .linkDropdown {
  border-radius:8px;
  margin:12px -20px 0px -20px;
}

.optionItem.Map .linkDropdownHead .calcItemHeadTab {
  min-height:36px;
}

.optionItem.Map .linkDropdown .calcItemHeadTabItem {
  padding:0px 10px;
}

.optionItem.Map .linkDropdown .calcItemUnitDecorUnselected {
  margin-left: 8px;
  margin-right: -5px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxBody {
  border-top-width:0px;
  border-bottom-width:0px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxDecor {
  display:none;
}

.optionItem.Map .linkingBoxFooter {
  border:1px solid #e8e8e8;
}

.optionItem.Map .linkingBoxBody .linkingComponent {
  margin:0px 7px;
}

.optionItem.Map .mapLinkingContainer .linkingComponent {
  margin:0px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxItem {
  padding-top:0px;
}

.optionItem.Map .mapLinkingContainer .databaseOperationModule {
  border-radius:8px;
}

.optionItem.Map .mapLinkingContainer .databaseOperationModule .domBody {
  border-radius: 0px 0px 8px 8px;
}


.optionItem.Map .mapLinkingContainer .linkingComponent.rowOperationWrapper .databaseItemHead {
  border-top-width:0px;
}

.optionItem.Map .mapLinkingContainer .domHeader {
  gap:0px;
  padding:7px;
}

.optionItem.Map .mapLinkingContainer .domHeader .domLabel {
  color: #888888;
  padding:0px 6px;
}

.optionItem.Map .mapLinkingContainer .domHeader .calcItemUnitMainDropdown {
  min-height:32px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxFooter {
  padding:0px;
  margin:0px 7px;
  border-radius:8px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxFooter .linkLabel {
  color: #888888;
  font-weight:400;
  padding: 0px 6px;
  margin-bottom:0px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxFooter .linkingToBox {
  padding:0px;
  margin-top:6px;
  border-radius:8px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxFooter .custom-select-head {
  padding:7px 12px;
}

.optionItem.Map .mapLinkingContainer .linkingBoxFooter .custom-select-head .selected-option {
  font-size:12px;
}

.optionItem.Map .optionHeadTabs {
  background-color: #ffffff;
  box-shadow:0 1px 1px rgba(0, 0, 0, .06);
}

.calculationWindow .calcItemBodyMain {
  padding:20px 15px;
}

.calculationWindow .mainCalculationItems {
  gap:16px;
}

.calculationWindow .mainCalculationItems > .calcItemBodyMainHeader {
  /* display:none; */
}

.calculationWindow .mainCalculationItems > .calcItemUnit {
  margin-top:0px;
  border-width:0px;
}

.calculationWindow .mainCalculationItems > .calcItemUnit .calcItemUnitMain {
  padding:0px;
}

.calculationWindow .mainCalculationItems > .calcItemUnit .calcItemUnitMain .optionInputIconBoxField {
  font-weight:400;
  padding-left:0px;
}

.calculationWindow .mainCalculationItems > .calcItemUnit .calcItemUnitMain .optionInputIconBox {
  box-shadow: none;
  border-width:0px;
  border-radius:0px;
  background-color:transparent;
}

.calculationWindow .calcHead .addOperation {
  width:36px;
  height:36px;
  display: flex;
  color: #ffffff;
  max-width:36px;
  font-size: 12px;
  line-height:10px;
  border-radius:10px;
  flex-direction: column;
  background-color:#212121;
  box-shadow:inset 0px 0px 6px rgba(0, 0, 0, 0.16);
}

.ao.two , .ao.three {
  display:none;
}

.calculationWindow .urlParamLabelKey {
  display:none;
}

.unitOfValue .calcItemUnitMain {
  display:flex;
  min-height:36px;
  justify-content: center;
}

.decorRemoval > .calcItemUnit > .calcItemUnitBox > .calcItemUnitDecor {
  margin-right:0px;
}

.sliderConfigBox .linkingOpen {
  flex-direction: row;
}

.sliderConfigBox .linkingOpen .cGrey {
  color: #9d9d9d;
  font-size: 12px;
  font-weight: 500;
}

.adItem.one {
  margin-top:-2px;
}

.adItem.two {
  margin-left:8px;
  margin-right:4px;
}

.inputDirectionItem {
  opacity: 0.3;
}

.optionItem.Linking .optionHeadTabs {
  background-color: #ffffff;
  box-shadow:0 1px 1px rgba(0, 0, 0, .06);
}

.imageUploadIco {
  width:30px;
  height:30px;
  opacity: 0.5;
}

.imageInputWrapper {
  flex:1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageUploadButton {
  opacity: 0.5;
  color: #FFFFFF;
  font-size:12px;
  margin-top:10px;
  padding: 4px 12px;
  border-radius: 7px;
  background-color:#212121;
}

.isFocused , .floatingProperties {
  position: relative;
}

.isFocused::before {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  content: '';
  position: absolute;
  outline: 2px solid #397dff;
  z-index: 1;
  pointer-events: none;
  outline-offset: 1px; 
}

.canvas-container .floatingProperties::before {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  content: '';
  position: absolute;
  outline: 2px solid rgba(173, 201, 255);
  z-index: 999;
  pointer-events: none;
  outline-offset: 1px; 
}

.isFocused.floatingProperties::before {
  outline: 2px solid #397dff;
  pointer-events: none;
  outline-offset: 1px; 
}

.scroll-container::after {
  content: '';
  display: block;
  height: 50px;
}

.twoOptionsInRow .custom-select-head > [alt="icon"] {
  margin-top: -6px;
  margin-left:12px;
  margin-right:-6px;
  width:14px !important;
  height:14px !important;
}

.twoOptionsInRow > .options-list > .optgroup > .optgroup-options {
  display:flex;
  margin-left:0px !important;
}

.twoOptionsInRow > .options-list > .optgroup > .optgroup-options > .option > [alt="icon"]  {
  width:12px !important;
  height:12px;
  margin-top:-2px;
  margin-right:8px;
  opacity: 0.3;
  object-fit: contain;
}

.inputOptionsLabel {
  padding:8px;
  font-weight: 700;
}

.twoOptionsInRow.custom-select .options-list {
  /* bottom: 72px; */
}

.calculationOutside .calculationOutside {
  z-index:999;
}

.calcItemHeadAction.calcHeadAdditiveUnit {
  gap:15px;
  padding: 15px 15px 0px 15px;
}

.calcHeadAdditiveUnit > .custom-select  {
  flex:1;
  display:flex;
}

.calcHeadAdditiveUnit > .custom-select > .custom-select-head {
  padding:0px 10px;
  border-radius:12px;
}

.calcItemHead.calcHeadAdditiveUnit > .calcItemRemove {
  min-width:36px;
  margin-right:0px;
}

.calcItemUnitMainDropdownLabel {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.calculationWindow .calcItemDecor {
  opacity: 0;
}

.calcItemDatabaseFilter::before {
  opacity: 0;
}

.calcItemBodyMainHeaderSecondary {
  margin-top:0px;
}

.specialColorInput .optionColorItem {
  flex:1;
  flex-direction: column;
  align-items: stretch;
}

.specialColorInput .optionColorItem > div {
  flex:1;
  display:flex;
}

.specialColorInput .optionColorCircle {
  width:100%;
  height:100%;
  /* outline-width: 0px; */
  box-shadow:0 1px 1px rgba(0, 0, 0, .06);
}

.optionConditionItemInner > .conditionDropdown > div > .calcItemHead > .calcItemHeadTab {
  gap:10px;
}

.mapPrimaryUnit > .calcItemUnit {
  margin-top:0px;
}

.mapPrimaryUnit .mapLinkingWrapper {
  margin:0px;
}

.maximizeDropdownItem {
  flex:1;
  display:flex;
}

.maximizeDropdownItem > .custom-select {
  flex:1;
  display:flex;
}

.maximizeDropdownItem > .custom-select > .custom-select-head {
  padding:0px 10px;
  border-radius:12px;
}

.mainMaximal > .custom-select-head {
  min-height:32px;
  padding:0px 10px;
}

.mainMaximal > .custom-select-head > .selected-option {
  font-size: 12px;
  font-weight: 300;
  color: #888888;
}

.customValueBox > .calcItemUnitBox > .calcItemUnitDecor {
  display:none;
}

.customValueBox > .calcItemUnitBox > .calcItemUnitMain > .woloEditor > .weInner > .weMainWrapper > .rte-placeholder  {
  left:0px;
}

.customValueBox > .calcItemUnitBox > .calcItemUnitMain > .woloEditor > .weInner > .weMainWrapper > .rich-text-editor {
  padding-top:0px;
  padding-left:0px;
}

.customValueBox > .calcItemUnitBox > .calcItemUnitMain > .woloEditor.pastFocused > .weInner > .weMainWrapper > .rich-text-editor {
  min-height:40px;
  max-height:40px;
}

.customValueBox > .calcItemUnitBox > .calcItemUnitMain > .woloEditor.neverFocused > .weInner > .paramListRow {
  right:0px;
  bottom:0px;
  position:absolute;
  padding-right:0px;
  padding-bottom:0px;
}

.customValueBox > .calcItemUnitBox > .calcItemUnitMain > .woloEditor.pastFocused > .weInner > .paramListRow {
  padding-left:0px;
  padding-bottom:0px;
}

.customValueBox > .calcItemUnitBox > .calcItemUnitMain > .calcItemUnitMainLabel {
  font-size: 12px;
  color: #9d9d9d;
  /* font-weight: 500; */
  margin-bottom:0px;
}

.dtCustomFormat {
  gap:8px;
  padding:10px;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
}

.dtCustomFormat > input {
  height:32px;
  color:#888888;
  font-size:12px;
  padding:0px 10px;
  border-width:0px;
  border-radius:8px;
  background-color:#f5f5f5;
}

.dtCustomFormatLabel {
  font-size: 12px;
  font-weight: 300;
}

.macroConsoleLabelRow {
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top:4px;
}

.macroConsoleLabel {
  font-size: 12px;
  font-weight: 300;
}

.mcTabs {
  padding:2px;
  display:flex;
  border-radius:8px;
  min-height:32px;
  background-color:#f5f5f5;
  margin-top:8px;
}

.mcTabItem {
  flex:1;
  display:flex;
  align-items: center;
  justify-content: center;
  padding:4px 4px;
  border-radius:8px;
  font-size:12px;
}
.mcTabItem.active {
  background-color:#ffffff;
}

.mcTable {
  border-radius:8px;
  border:1px solid #eee;
  overflow: hidden;
  margin-top:12px;
}

.mcTable table {
  width:100%;
  font-size:12px;
}

.mcTable th {
  font-weight: 500;
  padding:6px 10px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.mcTable td {
  padding:6px 10px;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}

.mcTable table tr:nth-last-child(1) td {
  border-bottom-width:0px;
}

.mcTable .mcTwo {
  border-right-width:0px;
}

.mcTable .mcThree {
  display:none;
}

.calcHeadAdditiveUnit > .maximizeDropdownItem > .twoOptionsInRow > .options-list > .optgroup > .optgroup-options > .option {
  white-space: nowrap;
}

.optionItem.Map > .optionItemInner > .optionItemBody > .optionItemRow > .linkingBoxWrapper > .linkingBoxMain > .mapPrimaryUnit > .calcItemUnit > .calcItemUnitBox .calcItemUnitDecor {
  margin-right:0px;
}

.calculationWindow .calcItemBody .calcItemUnitDecor {
  display:none;
}

.conditionDropdown > div > div > .calcItemBody > .calcItemBodyMain > .calcItemUnit > .calcItemUnitBox > .calcItemUnitDecorUnselected {
  margin-right:0px;
}

.conditionDropdown > div > div > .calcItemBody > .calcItemBodyMain > .calcItemUnit > .calcItemUnitBox .calcItemUnitDecor {
  display:none;
}

.dropdownHeightAdjust .custom-select-head {
  min-height:32px;
  padding:0px 10px;
}

.dualInfoBox {
  display:flex;
  padding-top:10px;
  flex-direction: column;
}

.dualInfoLabel {
  font-size: 12px;
  font-weight: 300;
  color: #171717;
  margin-bottom: 6px;
}

.dualInfoInput {
  color: #888888;
  font-size: 12px;
  font-weight:300;
  min-height:32px;
  border-width:0px;
  padding:0px 10px;
  border-radius:8px;
  background-color:#f5f5f5;
}





.custom-select .options-list {
  padding:6px;
  min-width:100%;
  overflow-y:auto;
  margin-top: 10px;
  max-height:216px;
  position:absolute;
  border-radius: 8px;
  background-color:#ffffff;
  border: 1px solid #dbdce3; 
  box-shadow: 0px 3px 6px rgba(0,0,0,0.10);
  z-index:99;
}

.custom-select .options-list::-webkit-scrollbar {
  width: 10px;
}

.custom-select .options-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.custom-select .options-list::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}

.custom-select .options-list .option {
  flex:1;
  border-radius:8px;
  position: relative;
  padding:10px 34px 10px 10px;
}

.custom-select .options-list .option.active::before {
  content:'';
  top:0px;
  right:6px;
  bottom:0px;
  width:16px;
  height:16px;
  margin:auto;
  filter:invert(1);
  position:absolute;
  border-radius:999px;
  background-color:#FFFFFF;
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url('../Assets/img/user/check.png');
}

.custom-select .options-list .option:hover {
  background-color:#eeeeee;
}

.mqDropdown {
  position: relative;
}

.mqDropdownHead {
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  flex-direction: row;
  align-items: center;
  background-color: #eaeaea;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .06);
}

.mqDropdownHead span {
  font-size:12px;
}

.mqDropdownHead .optionDatabaseSelectChevron {
  margin-left:8px;
  margin-right:0px;
}

.mqDropdownBody {
  right:0px;
  z-index:99;
  width:150px;
  padding: 6px;
  min-width: 100%;
  overflow-y: auto;
  margin-top: 10px;
  max-height: 216px;
  position: absolute;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #dbdce3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.10);
}

.mqDropdownBody::-webkit-scrollbar {
  width: 10px;
}

.mqDropdownBody::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 2px;
}

.mqDropdownBody::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 4px;
}

.mqDropdownBody div {
  flex:1;
  border-radius:8px;
  position: relative;
  padding:10px 34px 10px 10px;
}

.mqDropdownBody div.active::before {
  content:'';
  top:0px;
  right:6px;
  bottom:0px;
  width:16px;
  height:16px;
  margin:auto;
  filter:invert(1);
  position:absolute;
  border-radius:999px;
  background-color:#FFFFFF;
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-image:url('../Assets/img/user/check.png');
}

.mqDropdownBody div:hover {
  background-color:#eeeeee;
}

.bareGreenTabs {
  gap:8px;
  display:flex;
}

.bareGreenTabs div {
  color:#bdbdbd;
}

.bareGreenTabs div.active {
  color:#12B505;
}

.calcItemBody > .calcItemBodyMain > div > .calcItemUnit > .calcItemUnitBox > .calcItemUnitMain > .linkingAdjustDropdown > .custom-select > .custom-select-head > .optionDatabaseSelectChevron {
  margin-right:10px;
}

.calcItemBody > .calcItemBodyMain > div > .calcItemUnit > .calcItemUnitBox > .calcItemUnitMain > .linkingAdjustDropdown > .custom-select > .custom-select-head > .selected-option {
  min-height:32px;
  max-height:32px;
}

.adjustLabelSpacing {
  margin-top:12px;
}

.calcRteItem > .neverFocused > .weInner > .paramListRow {
  right:0px;
  bottom:1px;
  padding:0px;
  position:absolute;
}

.calcRteItem > .pastFocused > .weInner > .paramListRow {
  padding-left:2px;
  padding-bottom:2px;
}

.calcRteItem > .woloEditor > .weInner > .paramListRow > .paramListItem {
  background-color:transparent;
}

.calcRteItem > .neverFocused > .weInner > .paramListRow > .paramListItem {
  background-color:transparent;
}

.calcRteItem > .woloEditor > .weInner > .weMainWrapper > .rte-placeholder {
  top:7px;
  left:10px;
  font-size:12px;
}

.calcRteItem > .woloEditor > .weInner > .weMainWrapper > .rich-text-editor {
  font-size:12px;
  padding-top:8px;
  padding-left:10px;
  min-height:45px;
  max-height:45px;
}

.calcRteItem > .neverFocused > .weInner > .weMainWrapper > .rich-text-editor {
  min-height:32px;
  max-height:32px;
}

.calcRteItem > .woloEditor > .weInner {
  border-radius:8px !important;
  background-color:#f5f5f5;
}

.pointBClass {
  margin-top:12px;
}

.secondValueLabel {
  color: #171717;
  font-size: 12px;
  font-weight: 300;
  margin-top:10px;
  margin-bottom:6px;
}

.labelRowInfo {
  display:flex;
  align-items: center;
  justify-content: space-between;
  color: #171717;
  font-size: 12px;
  font-weight: 300;
  margin-bottom:6px;
}

.rightTag {
  color:#12B505;
}

.database-selector-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 3px;
  background: #dedede;
}
.database-selector-tab .database-selector-tab-item {
  flex: 1;
  text-align: center;
  padding: 2px;
  border-radius: 5px;
}

.database-selector-tab .database-selector-tab-item.active {
  background: #ffffff;
}